import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAdminLoginData, setAdminAuthData } from "../redux/actions";
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/Blacktransparent.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyConstant from '../config/MyConstant';

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                email: "",
                password: "",
                login_type: "",
            }
        }
    }

    submit() {
        let { email, password } = this.props;

        if(email === "admin@teqbahn.com" && password === "teqbahn#234") {
            this.props.setAdminAuthData("isAdminLoggedIn", "1");
            this.props.setAdminAuthData("loggedSession", "");
            window.location.href = MyConstant.keyList.projectUrl+'dashboard'
        } else {   
            toast.error('Incorrect email or password.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        let { email, password } = this.props;
        let { errors } = this.state;
        return (
            <div className="super-admin-module">
                <ToastContainer />
                <div className="container vcenter">
                    <div className="card card-container">
                        <img id="profile-img" className="img-responsive" src={Logo} />
                        <form className="form-signin">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email"
                                    className={(typeof errors['email'] != "undefined" && errors['email'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="Email address" value={email} onChange={(e) => { this.props.setAdminLoginData("email", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.email}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Password</label>
                                <input type="password" name="password" id="password"
                                    className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="***********" value={password} onChange={(e) => { this.props.setAdminLoginData("password", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.password}</div>
                            </div>
                            <button className="btn btn-lg btn-primary btn-block btn-signin" type="button" onClick={() => this.submit()}>Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.adminLoginReducer.email,
        password: state.adminLoginReducer.password,
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAdminLoginData: (key, value) => dispatch(setAdminLoginData(key, value)),
        setAdminAuthData: (key, value) => dispatch(setAdminAuthData(key, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);