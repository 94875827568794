import React from 'react';
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css'
import '../slides.css'
import Jpg1 from './tilli/1.jpg'
import Jpg2 from './tilli/2.jpg'
import Jpg3 from './tilli/3.jpg'
import Jpg4 from './tilli/4.jpg'
import Jpg5 from './tilli/5.jpg'
import Jpg6 from './tilli/6.jpg'
import Jpg7 from './tilli/7.jpg'
import Jpg8 from './tilli/8.jpg'
import Jpg9 from './tilli/9.jpg'

class SlideTilli extends React.Component {

    render() {

        return (
            <div>
                <div style={{padding: 20}}>
                    <a href={'https://teqbahn.com/'} style={{color: 'blue'}}>Home</a>
                </div>
                <Slide easing="ease" autoplay={false}>
                    <div className="each-slide">
                        <img src={Jpg1} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg2} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg3} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg4} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg5} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg6} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg7} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg8} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <a href="https://www.tillikids.org/" target={'_blank'}>
                            <img src={Jpg9} alt="" style={{width: '100%', height: '100%'}}/>
                        </a>
                    </div>
                </Slide>
            </div>
        );
    }
}

export default SlideTilli
