import React, { useEffect, useState } from "react";
import Confetti from 'react-confetti'

let { width, height } = window.screen;
function WelcomeBanner(props) {
    let [bannerState, setBannerState] = useState(false);

    useEffect(() => {
        let bannerState = localStorage.getItem("banner-closed");
        if (bannerState) {
            setBannerState(false)
        }
    }, [])
    return bannerState && <div className="welcome-banner">
        <div className="welcome-banner-close" onClick={() => {
            setBannerState(false)
            props.bannerClose()
            localStorage.setItem("banner-closed", true)
        }}>
            <i className="fa fa-times" />
        </div>
        <div className="health-summit">
            Thank you to Participants of World Health Summit Berlin <br />
            2023 Theme: A Defining Year for Global Health Action
        </div>

        {
            width && height && <Confetti
                width={width}
                height={height}
            />
        }
    </div>;
}

export default WelcomeBanner;