import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import MyConstant from '../config/MyConstant';



export default class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileObj: {}
        }
    }

    componentDidMount() {
    }


    async filePostApi() {
        let { fileObj } = this.state
        let dataJson = fileObj
        if (dataJson != "{}" && Object.keys(dataJson).length > 0) {
            var i = 0
            const postFileUpload = new FormData();
            postFileUpload.append('file' + (i + 1), dataJson.file);
            postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
            postFileUpload.append('processType' + (i + 1), dataJson.processType);
            postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
            //  postFileUpload.append('userId', dataJson.userId);
            // alert(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName)

            fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName, {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(json => {
                return json.response;
            }).catch(error => console.warn(error));
        }
    }
    render() {
        return (<>
            <div class="container">
                <h2 style={{ textAlign: "center" }}>Image Upload</h2>
                <div class="form-group">
                    <div className="row">
                        <div className='col-4 col-sm-4' />
                        <div className='col-4 col-sm-4'>
                            <label >Image :</label>
                            <input type="file" onChange={async (event) => {
                                var files = event.target.files;
                                var length = files.length;
                                var urlfile = ''
                                if (length > 0) {
                                    for (var i = 0; i < length; i++) {
                                        var fileUrl = URL.createObjectURL(files[i]);
                                        var file = files[i];
                                        var filename = file.name;
                                        var ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                                        // if(file.type == "image/jpeg"){
                                        //     ext = "jpeg";
                                        // }
                                        urlfile = fileUrl
                                        var uid = uuidv4();
                                        var fileObj = {
                                            file: file,
                                            fileUrl: fileUrl,
                                            fileName: uid + "." + ext,
                                            docsId: uid,
                                            processType: "coverimage",
                                            fileType: "image"
                                        };
                                        console.log("fileObj", fileObj)
                                        this.setState({ fileObj, fileType: ext })

                                    }

                                }
                            }
                            }
                            />

                        </div>
                        <div className='col-4 col-sm-4' />
                    </div>
                </div>
                <div className='row mt-2 pt-2' style={{ textAlign: "center" }}>
                    <button className="btn btn-success" onClick={() => {
                        this.filePostApi()
                    }} >Submit</button>
                </div>

            </div>

        </>)
    }

}