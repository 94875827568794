import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import Slide1 from '../images/csit/TeqbahnLabs1.png';
import Slide2 from '../images/csit/TeqbahnLabs2.png';
import Slide3 from '../images/csit/TeqbahnLabs3.png';
import Slide4 from '../images/csit/TeqbahnLabs4.png';
import Slide5 from '../images/csit/TeqbahnLabs5.png';
import Slide6 from '../images/csit/TeqbahnLabs6.png';
import Slide7 from '../images/csit/TeqbahnLabs7.png';
import Slide8 from '../images/csit/TeqbahnLabs8.png';
import Slide9 from '../images/csit/TeqbahnLabs9.png';
import Slide10 from '../images/csit/TeqbahnLabs10.png';



class CarouselCsit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      holdImage: true,
      oneTimeSet: false,
    }
  }

  changeStatus(type) {
    this.setState({ holdImage: type })
  }
  render() {
    let { holdImage } = this.state
    return (
      <div className='responsive csit'>
        <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <span onClick={onClickHandler}>
                <a href="javascript:void(0)" onClick={() => {
                  this.changeStatus(true)
                }} className='btn btn-sm  prevbtn'>
                  <i className='fa fa-chevron-left' style={{ fontSize: "26px", color: "#e2915b" }} />
                </a>
              </span>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <span onClick={onClickHandler}>
                <a href="javascript:void(0)" onClick={() => {
                  this.changeStatus(true)
                }} title={label} className='btn btn-sm nextbtn' >
                  <i className='fa fa-chevron-right' style={{ fontSize: "26px", color: "#e2915b" }} />
                </a>
              </span>
            )
          }
          showThumbs={false}
          animationHandler="fade"

        >

          <div className='' onClick={() => {
            this.changeStatus(false)
          }} >
            <img src={Slide1} className='carosal_img' style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide2} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>

          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide3} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide4} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide5} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide6} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide7} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide8} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide9} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
          <div onClick={() => {
            this.changeStatus(false)
          }}>
            <img src={Slide10} style={{ height: window.innerWidth > window.innerHeight ? (window.innerHeight - 140) : "auto" }}
            />
          </div>
        </Carousel>
      </div>
    )
  }
}
export default CarouselCsit;
