import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Slide1Bg from '../images/version4/Slide1.png'
import Slide2Bg from '../images/version4/Slide2.jpg'
import Slide2S1 from '../images/version4/Slide2S1.png'
import Slide3Bg from '../images/version4/Slide3.png'
import Slide3S1 from '../images/version4/Slide3S1v2.png'
import Slide4Bg from '../images/version4/Slide4.png'
import Slide4S1 from '../images/version4/Slide4S1.png'
import Text from './component/AnimatedText';
import { Link } from "react-router-dom";
import Config from '../helper/Config';

export default function LandingPage4(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("Home", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 100 }} className='main-container version4'>
            <div className='slide1 d-flex justify-content-center align-items-center text-center' style={{ backgroundImage: `url(${Slide1Bg})` }}>
                welcome to a world <br/>of meaningful <br/>connectivity
            </div>
            <div className='slide2 d-flex align-items-center' style={{ backgroundImage: `url(${Slide2Bg})` }}>
                <div className='flex-1 left'>
                    <div className='slide-content slide-content-2'>
                        <span className='header'>
                            The Decentralized <br/>
                            Teqit Cloud
                            <br/>
                            <span className='content'>
                                A Zero Trust Environment <br/>
                                Localized Deployment <br/>
                                At Scale<br/>
                            </span>
                        </span>
                    </div>
                </div>
                <div className='slide-image'>
                    <div className='s2img'>
                        <img src={Slide2S1} className='img-fluid'/>
                    </div>
                </div>
            </div>
            <div className='slide3 d-flex align-items-center' style={{ backgroundImage: `url(${Slide3Bg})` }}>
                <div className='flex-1 left'>
                    <div className='slide-content'>
                        <div className='header'>
                            Data without Ambiguity <br/>
                        </div>
                    </div>
                </div>
                <div className='slide-image'>
                    <div className='s3img'>
                        <img src={Slide3S1} className='img-fluid'/>
                    </div>
                </div>
            </div>
            <div className='slide4 d-flex align-items-center' style={{ backgroundImage: `url(${Slide4Bg})` }}>
                <div className='flex-1 left'>
                    <div className='slide-content'>
                        <div className='header'>
                            <Text /> 
                        </div>
                    </div>
                </div>
                <div className='slide-image'>
                    <div className='s4img'>
                        <img src={Slide4S1} className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
