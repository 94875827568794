import React from 'react';
import { Style } from "react-style-tag";
import Logo from './images/BlackTransparentNew.png'
import innovation from './icons/innovation.png'
import Parliament from './icons/Parliament.png'
import Recruitment from './icons/Recruitment.png'
import noun_Not_Equal from './icons/noun_Not_Equal.png'
import noun_retention from './icons/noun_retention.png'
import coronaimage_bw from './images/coronaimage2.png';
import Bg2 from '../src/icons/mountain.png';

import { Link } from "react-router-dom";
import Header from './version2/component/Header';
import Africa from './images/version3/Africa.jpg';
import India from './images/version3/India.jpg';
import Nairobi from './images/version3/Nairobi.jpg';
import Tijuana from './images/version3/Tijuana.jpg';
import Tijuanalandscape from './images/version3/Tijuanalandscape2.jpg';
import LearnAboutUs from './version3/LearnAboutUs';
import { analyticsUserTrack } from './config/analyticsTrack';
import Config from './helper/Config';

class LandingPage_3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            logoWidth: 350,
            mainTextAlignClass: "text-right"
        };
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        let deviceWidth = window.innerWidth;
        let { logoWidth } = this.state;
        if (deviceWidth < 350) {
            logoWidth = 250
        }

        this.setState({
            logoWidth
        })
        this.handleResize()
        analyticsUserTrack("Social Impact", "Landing");
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        let el = document.getElementById('content');
        let divHeight = el.offsetHeight

        console.log("divHeight", divHeight)
        let mainTextAlignClass = "single-line"
        if(divHeight > 90) {
            mainTextAlignClass = "multi-line"        
        }

        this.setState({
            mainTextAlignClass
        })
    }
      
    render() {
        let { mainTextAlignClass } = this.state;
        let { bannerState } = this.props;
        return (
            <React.Fragment>
                <Style>
                    {`
                    .lastRow {
                        height: 600px;
                        padding-bottom: 50px;
                        padding-top: 40px;
                    }
                    .lastRow1 {
                        padding-top: 100px;
                        padding-left:50px
                    }
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
                .threeViewB {
                    paddingBottom : 30px
                }
                .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 200px; padding-right: 200px
                }
                .nowrap {
                    white-space: nowrap !important;
                }
                .sticky {
                  padding: 10px;
                  top: 0;
                  z-index:1;
                  background-size: cover;
                  background-repeat: no-repeat
                }
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                  
                  .boldcontent{
                        text-align: center;
                        font-family: Helvetica-Regular;
                        font-size:30px;font-weight:bold;
                        white-space: nowrap !important;
                      }
                   
                .p-tag{
                    font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                 }
                 .div_bg_img {
                    padding: 10px;
                    top: 0;
                    z-index:1;
                    background-color:#d4d4d4;
                    box-shadow:0px 5px 4px #888788;	
                  }
                
                .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  }                    
                .div_height{
                    height: 150px;
                    }
                   .image_1{
                    background: url(${coronaimage_bw});
                   width:100%;
                   height:100%;
                   background-repeat:no-repeat;
                   background-size:contain ;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    /*manage top*/
                    margin-top:45px
}
                   }  
                   
                  @media screen and (min-width:900px) {
                    .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                       }
                       
                       .lastRow {
                        height: 600px;
                    }
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                  
                      
                  }
                
                @media screen and (max-width:1000px) {
               
                       .threeViewB {
                        padding-bottom : 40px
                    }
                    
                    .lastRow {
                        height: 700px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
                  
                   
                  @media screen and (max-width:600px) {
                  .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 20px; padding-right: 20px
                    }
                    .threeViewB {
                        padding-bottom : 40px
                    }
                     
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                    
                      .believecontent{
                          margin-top:70px;
                          white-space: nowrap;
                         
                      }
                      
                      .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                      }
                      .custom-p-tag {
                        font-size:1rem !important;
                    }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        // background-repeat: round !important;
                    }
                    .div_bg_img{
                        min-height: 230px
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    
                }
                
                @media screen and (min-width: 600px) and (max-width:780px) {
           
                    
                    .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
               `}
                </Style>
                <div className='version-2'>
                    <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
                        <div className={"home-sticky-crop home"} style={{ top: 0 }}>
                            <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                                <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                            </Link>
                            <Header page="social-impact" />
                        </div>
                    </div>
                    <div style={{ marginTop: 100 }}></div>
                    <div className='d-flex justify-content-center py-5 main-header-content' >
                        <div className={`health-header-box ${mainTextAlignClass}`} style={{ fontSize: 32, alignItems: "center"}} id="content">
                            <div className={`health-header`}>Scaling Communication Systems Where it Matters Most</div>
                            {/* <div className="scrolling-words-box">
                                <div className='scrolling-words'>
                                    <div className='scroll-text'>Point of Evaluation</div>
                                    <div className='scroll-text'>Point of Care</div>
                                    <div className='scroll-text'>Point of Violence</div>
                                    <div className='scroll-text'>Point of Response</div>
                                    <div className='scroll-text'>Point of Medical Supply</div>
                                    <div className='scroll-text'>Point of Evaluation</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    
                    <div className='d-flex'>
                        <div className='home-image'>
                            <img className='img-fluid' src={Africa} />
                        </div>
                        <div className='home-image'>
                            <img className='img-fluid' src={India} />
                        </div>
                        <div className='home-image'>
                            <img className='img-fluid' src={Nairobi} />
                        </div>
                        <div className='home-image'>
                            <img className='img-fluid' src={Tijuana} />
                        </div>
                    </div>
                    
                    {/* <div className='border-economic-innovation'>
                    </div> */}
                    <div className='container '>
                        <div className='pt-2 pb-5'>
                            <h4 className='font-family-helvetica' style={{ fontWeight: "normal", lineHeight: "28px"}}>
                                We believe in a rights based approach to economic innovation by centering agency and participation in the basic right to access information technology. We apply a community-led innovation paradigm to scale technology for the benefit of the many. Our team came together 25 years ago with a dream to deliver equitable technology. Today, we are collaborating and co-creating with powerful thinkers in vibrant settings like Mexico, India, Rwanda, Kenya, Nigeria, and Cameroon. Listening is a powerful catalyst for equity and we have been listening for a long time.
                            </h4>
                        </div>
                    </div>
                    <div className=''>
                        <img className='img-fluid' src={Tijuanalandscape} />
                    </div>
                    <div style={{ padding: 50 }}></div>
                    <div>
                        <div className='text-center' style={{ paddingBottom: 50}}>
                            <p style={{ color: 'black', fontFamily: 'Helvetica-Regular', fontSize: 30 }}>
                                Learn About Us
                            </p>
                        </div>
                        <LearnAboutUs />
                    </div>

                    <div className={'row'} style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>
                        <div className="col-12" style={{ width: '100%', }}>
                            <div className="row res-rem-m_b" style={{ padding: 0, margin: 0, marginBottom: 0 }}>
                                <div className="col-sm-6 lastRow">
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                    }} src={Bg2} />
                                </div>
                                <div className="col-sm-6 lastRow" style={{ padding: 0, margin: 0 }}>
                                    <div className='center lastRow1' style={{ width: '100%' }}>
                                        <p style={{
                                            paddingBottom: 20,
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Bold',
                                            fontSize: 24
                                        }}>
                                            What we do
                                        </p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span style={{}}> <img src={innovation} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Develop Technology (T)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_Not_Equal} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Build Equitable Systems (eq)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_retention} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Increase access to economic innovation (bahn)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Recruitment} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Mission based ecosystems</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Parliament} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Collaborative Governance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='container mb-5'>
                        <div className='text-center py-5'>
                            <p style={{ color: 'black', fontFamily: 'Helvetica-Regular', fontSize: 30 }}>
                                Collaborators
                            </p>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='collabrators-box'>
                                <a href="https://openmrs.org/" target="_blank" title="OpenMRS">
                                    <img src={OpenMrs} className='img-fluid' />
                                </a>
                            </div>
                        </div>
                    </div> */}

                </div>

            </React.Fragment>
        );
    }
}

export default LandingPage_3;