import React from 'react';
import logo from '../images/speakup/speakupLogo.png';
import Footer from './Footer';
import Header from './Header';
import { ZiFetchRequest } from '../protobuf/umedme/umedme_grpc_web_pb';

export default class SpeakUpUnsubscribe extends React.Component {

    componentDidMount() {
        let { id, email } = this.props.match.params;
        this.unsubscribe(id)
    }

    unsubscribe(token) {
        let postJson = { token }
        const request = new ZiFetchRequest();
        request.setDomain('speakUp');
        request.setEvent('removeFromSpeakUpMailingList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                var response = responseData.response
                // console.log("response", response)
            }
        });
    }

    render() {
        return <div className='speakup-page'>
            <Header />
            <div className='body'>
                <div className='container'>
                    <div className="unsubscribe-box" style={{ margin: "3em 0"}}>
                        <div className='header-content'>Unsubscription was successful!</div>
                        <div className='body-content'>We won't send any more emails.</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>;
    }
}
