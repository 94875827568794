import React from 'react';
import { Style } from "react-style-tag";
import Logo from '../images/BlackTransparentNew.png'
import Handsontree from '../images/handsontree.jpg';
import O_photo from '../images/O_photo.jpg';
import O_photoCropped from '../images/O_photoCropped.jpg';
import what_it_looks_like from '../images/what_it_looks_like.jpg';
import innovation from '../icons/innovation.png'
import Parliament from '../icons/Parliament.png'
import Recruitment from '../icons/Recruitment.png'
import noun_Not_Equal from '../icons/noun_Not_Equal.png'
import noun_retention from '../icons/noun_retention.png'
import coronaimage_bw from '../images/coronaimage2.png';
import WhoWeAre from '../images/WhoWeAre.jpg';
import Bg2 from '../icons/mountain.png';
import project_1 from '../images/project/project_1.png';
import project_2 from '../images/project/project_2.png';
import project_3 from '../images/project/project_3.png';
import project_4 from '../images/project/project_4.png';
import project_5 from '../images/project/project_5.png';
import project_6 from '../images/project/project_6.png';
import project_7 from '../images/project/project_7.png';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';

class Mission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            logoWidth: 350
        };
    }

    componentDidMount() {
        analyticsUserTrack("Mission", "Landing");
        let deviceWidth = window.innerWidth;
        let { logoWidth } = this.state;
        if (deviceWidth < 350) {
            logoWidth = 250
        }

        this.setState({
            logoWidth
        })

        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky");
                } else {
                    header.classList.remove("sticky");
                }
            }
        };
    }

    render() {
        let { logoWidth } = this.state;
        let { bannerState } = this.props;
        return (
            <React.Fragment>
                <Style>
                    {`
                    .lastRow {
                        height: 600px;
                        padding-bottom: 50px;
                        padding-top: 40px;
                    }
                    .lastRow1 {
                        padding-top: 100px;
                        padding-left:50px
                    }
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
                .threeViewB {
                    paddingBottom : 30px
                }
                .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 200px; padding-right: 200px
                }
                .nowrap {
                    white-space: nowrap !important;
                }
                .sticky {
                  padding: 10px;
                  top: 0;
                  z-index:1;
                  background-image: url(${O_photoCropped});
                  background-size: cover;
                  background-repeat: no-repeat
                }
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                 
                   
                  .boldcontent{
                        text-align: center;
                        font-family: Helvetica-Regular;
                        font-size:30px;font-weight:bold;
                        white-space: nowrap !important;
                      }
                   
                .p-tag{
                    font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                 }
                 .div_bg_img {
                    padding: 10px;
                    top: 0;
                    z-index:1;
                    background-color:#d4d4d4;
                    box-shadow:0px 5px 4px #888788;	
                  }
                
                .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  }                    
                .div_height{
                    height: 150px;
                    }
                   .image_1{
                    background: url(${coronaimage_bw});
                   width:100%;
                   height:100%;
                   background-repeat:no-repeat;
                   background-size:contain ;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    /*manage top*/
                    margin-top:45px
}
                   }  
                   
                  @media screen and (min-width:900px) {
                    .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                       }
                       
                       .lastRow {
                        height: 600px;
                    }
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                  
                      
                  }
                
                @media screen and (max-width:1000px) {
               
                       .threeViewB {
                        padding-bottom : 40px
                    }
                    
                    .lastRow {
                        height: 700px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
                  
                   
                  @media screen and (max-width:600px) {
                  .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 20px; padding-right: 20px
                    }
                    .threeViewB {
                        padding-bottom : 40px
                    }
                     
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                    
                      .believecontent{
                          margin-top:70px;
                          white-space: nowrap;
                         
                      }
                      
                      .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                      }
                      .custom-p-tag {
                        font-size:1rem !important;
                    }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        // background-repeat: round !important;
                    }
                    .div_bg_img{
                        min-height: 230px
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    
                }
                
                @media screen and (min-width: 600px) and (max-width:780px) {
           
                    
                    .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }

                  .project_1{
                        background: url(${project_1});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_1:hover{
                        background-image: url(${project_1});
                        width:100%;filter: initial;
                   }
                  .project_2{
                        background: url(${project_2});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_2:hover{
                        background-image: url(${project_2});
                        width:100%;filter: initial;
                   }
                  .project_3{
                        background: url(${project_3});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_3:hover{
                        background-image: url(${project_3});
                        width:100%;filter: initial;
                   }
                  .project_4{
                        background: url(${project_4});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_4:hover{
                        background-image: url(${project_4});
                        width:100%;filter: initial;
                   }
                  .project_5{
                        background: url(${project_5});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_5:hover{
                        background-image: url(${project_5});
                        width:100%;filter: initial;
                   }
                  .project_6{
                        background: url(${project_6});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_6:hover{
                        background-image: url(${project_6});
                        width:100%;filter: initial;
                   }
                  .project_7{
                        background: url(${project_7});
                        width:100%;
                        height:100%;
                        background-repeat:no-repeat;
                        background-size:contain ;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                   }
                   .project_7:hover{
                        background-image: url(${project_7});
                        width:100%;filter: initial;
                   }
               `}
                </Style>
                <div>
                    <div className="img_full_view header-height" style={{
                        backgroundImage: `url(${O_photo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className="header img_full_view home-page-header" id="myHeader" style={{ width: '100%' }}>
                            <div className="col-12" style={{ minHeight: 120, width: '100%', padding: 20 }}>
                                <img className="logoImage" src={Logo} style={{ width: logoWidth, paddingTop: bannerState ? 30 : 0  }} />
                            </div>
                        </div>
                        <Header page="mission" />
                        <div style={{ color: 'white' }}>

                            <div className="row">

                                <div className="col-sm-12 col-xs-12 text-center center" style={{ top: '50%' }}>
                                    <p className="header-main-text">
                                        Technology process re-engineering (TPR) for
                                        carbon sensitive information technology.

                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={'row'} style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>
                        <div className="threeView">
                            <div className="col-md-4 col-sm-6">
                                <div className="row" style={{ height: 350, textAlign: 'center' }}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={WhoWeAre} />
                                </div>
                                <div className="row" style={{ paddingTop: 20 }}>
                                    <div className="col-sm-12">
                                        <p>
                                            <p className={'nowrap'} style={{
                                                textAlign: 'center',
                                                paddingBottom: 10,
                                                color: 'black',
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 24
                                            }}>
                                                Who we are
                                            </p>
                                            <p className="p-tag">We lead transformation and</p>
                                            <p className="p-tag">change management efforts</p>
                                            <p className="p-tag">by actively supporting the </p>
                                            <p className="p-tag">advancement of sustainable</p>
                                            <p className="p-tag">technology across sectors</p>
                                            <p className={'threeViewB'}></p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="row" style={{ height: 350, textAlign: 'center' }}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={Handsontree} />
                                </div>
                                <div className="row" style={{ paddingTop: 20 }}>
                                    <div className="col-sm-12">
                                        <p>
                                            <p className={'nowrap'} style={{
                                                textAlign: 'center',
                                                paddingBottom: 10,
                                                color: 'black',
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 24
                                            }}>
                                                What we believe
                                            </p>
                                            <p className="p-tag">
                                                A low resource model<br />
                                                focused on the <br />
                                                daily practice of<br />
                                                inclusion, equity,<br />
                                                and sustainability
                                            </p>
                                            <p className={'threeViewB'}></p>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="row" style={{ height: 350, textAlign: 'center' }}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={what_it_looks_like} />
                                </div>
                                <div className="row" style={{ paddingTop: 20 }}>
                                    <div className="col-sm-12">
                                        <p className={'nowrap'} style={{
                                            textAlign: 'center',
                                            paddingBottom: 10,
                                            color: 'black',
                                            fontFamily: 'Helvetica-Bold',
                                            fontSize: 24
                                        }}>
                                            What it looks like
                                        </p>
                                        <p className="p-tag">Collaborate to <br />
                                            raise awareness on <br />
                                            digital technology as a<br />
                                            primary carbon producer
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 30 }}></div>
                    <div className={'row'} style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>
                        <div className="col-12" style={{ width: '100%', }}>
                            <div className={'row'}
                                style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>

                                <div className="col-12 " style={{ textAlign: 'center', paddingBottom: 20 }}>
                                    <p style={{ color: 'black', fontFamily: 'Helvetica-Regular', fontSize: 30 }}>
                                        How We Get There
                                    </p>
                                </div>
                            </div>
                            <div className={'row'}
                                style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>

                                <div className="col-12 div_bg_img" style={{ paddingBottom: '0%' }}>
                                    <div className="project-div">
                                        <div className="logo zoom div_height">
                                            <a href="https://www.greenit.fr/wp-content/uploads/2019/11/GREENIT_EENM_etude_EN_accessible.pdf" target="_blank" title="The Environmental Footprint of the Digital World">
                                                <div className="project_4" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo zoom div_height">
                                            <a href="https://wedocs.unep.org/bitstream/handle/20.500.11822/37439/FB027.pdf" target="_blank" title="The Growing Footprint of Digitalisation">
                                                <div className="project_1" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo zoom div_height">
                                            <a href="https://theshiftproject.org/wp-content/uploads/2019/03/Lean-ICT-Report_The-Shift-Project_2019.pdf" target="_blank" title="Lean ICT Towards Digital Sobriety">
                                                <div className="project_2" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo zoom div_height">
                                            <a href="https://arxiv.org/pdf/1906.02243v1.pdf" target="_blank" title="Deep Learning in NLP">
                                                <div className="project_5" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo zoom div_height">
                                            <a href="https://bthechange.com/" target="_blank" title="B The Change">
                                                <div className="project_6" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo zoom div_height">
                                            <a href="https://en.reset.org/knowledge/our-digital-carbon-footprint-whats-the-environmental-impact-online-world-12302019" target="_blank" title="Our Digital Carbon Footprint">
                                                <div className="project_7" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="logo col-sm-2  zoom div_height">
                                            <a href="https://www.genevaenvironmentnetwork.org/resources/updates/data-digital-technology-and-the-environment/" target="_blank" title="Data, Digital Technology and the Environment">
                                                <div className="project_3" style={{}}></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row res-rem-m_b" style={{ padding: 0, margin: 0, marginBottom: 0 }}>
                                <div className="col-sm-6 lastRow">
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                    }} src={Bg2} />
                                </div>
                                <div className="col-sm-6 lastRow" style={{ padding: 0, margin: 0 }}>
                                    <div className='center lastRow1' style={{ width: '100%' }}>
                                        <p style={{
                                            paddingBottom: 20,
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Bold',
                                            fontSize: 24
                                        }}>
                                            What we do
                                        </p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span style={{}}> <img src={innovation} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Clean-Tech Innovation (T)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_Not_Equal} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Reduce structural inequalities (eq)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_retention} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Increase access to optimal resources (bahn)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Recruitment} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Recruit based on values</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Parliament} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Good governance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </React.Fragment>
        );
    }
}

export default Mission;