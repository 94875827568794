import {combineReducers} from 'redux';
import loginReducer from './loginReducer';
import signUpReducer from './signUpReducer';
import adminLoginReducer from './adminLoginReducer';
import authAdminReducer from './authAdminReducer';

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  signUpReducer: signUpReducer,
  adminLoginReducer: adminLoginReducer,
  authAdminReducer: authAdminReducer
});

export default rootReducer;