import React from 'react';
import './adminStyles.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import MyConstant from '../config/MyConstant';
import AdminTopMenu from "./AdminTopMenu"
import { loginStatusCheck } from './Common';



export default class UserPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postedData: [],
            noPagesLimit: 1,
            next: false,
            overallData: []
        }
    }


    componentDidMount() {
        loginStatusCheck()
        document.body.style.background = "#DCDCDC";
        this.getPostList()

        var that = this
        window.onscroll = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                // you're at the bottom of the page
                console.log("page end -->")
                that.loadNextPage()
            }
        };


    }



    async getPostList() {
        let { noPagesLimit, next, overallData } = this.state
        var loginType = localStorage.getItem("UserId");
        let { match } = this.props
        let { params } = match
        if (params.id) {
            loginType = params.id
        }
        console.log(params)
        //userId: String, pageLimit: Int, noOfPage:Int, totalResult: Long
        let postJson = { userId: loginType, pageLimit: 10, noOfPage: noPagesLimit, totalResult: 10 };
        console.log("**", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('user');
        request.setEvent('getFeedListBasedOnUser');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let parseData = JSON.parse(responseData)
                console.log("responseData", parseData)

                let responseOutJson = parseData.result
                overallData = [...responseOutJson, ...overallData]
                responseOutJson = overallData

                let responseMsg = parseData
                let totalResult = responseMsg.totalResult
                let currentResult = noPagesLimit * 10;
                if (currentResult < totalResult) {
                    next = true;
                }
                console.log("res", responseOutJson)

                this.setState({ postedData: responseOutJson, next, overallData })

            }
        });

    }

    redirectPage(feedId) {
        window.open(MyConstant.keyList.projectUrl + '/viewdetails/' + feedId)
    }


    deletePost(ival) {

        let chooseRes = window.confirm("Are you sure delete post!");
        if (chooseRes) {
            //userId: String, feedId: String
            let postJson = { userId: ival.userId, feedId: ival.feedData.id };
            console.log("postJson", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('user');
            request.setEvent('deleteFeed');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    console.log("responseData", responseData)
                    let parseData = JSON.parse(responseData)
                    console.log("responseData", parseData)
                    this.getPostList()
                }
            });
        }
    }

    loadNextPage() {
        let { noPagesLimit, next } = this.state
        console.log("next", next, noPagesLimit)
        if (next) {
            this.setState({
                noPagesLimit: noPagesLimit + 1, next: false
            }, () => {
                this.getPostList();
            })
        }
    }

    render() {

        let { postedData } = this.state

        var loginType = localStorage.getItem("loginUserType");

        var feedPostArray = []
        postedData.map((ival, i) => {
            let feedPostData = ival.feedData
            let imgPath = ""
            let imageShow = false
            if (feedPostData.coverImage) {
                imgPath = MyConstant.keyList.apiURL + "vp?action=coverimage&key=" + feedPostData.coverImage + "&id=" + "image"
                imageShow = true
            }

            let userPhoto = ""

            if (ival.userPhoto) {
                userPhoto = MyConstant.keyList.apiURL + "vp?action=coverimage&key=" + ival.userPhoto + "&id=" + "image"
            }

            let descriptionContent = feedPostData.description
            if (descriptionContent) {
                if (imageShow) {
                    descriptionContent = descriptionContent.substring(0, 200);
                }
                else {
                    descriptionContent = descriptionContent.substring(0, 5000);
                }

            }

            let readMoreShow = false
            if (feedPostData.description.length != descriptionContent.length) {
                readMoreShow = true
            }
            // console.log(feedPostData.description.length, "----", descriptionContent.length)
            feedPostArray.push(<>
                <div className='card'>
                    <div className='row' style={{ paddingBottom: 10 }}>
                        <div className='col-sm-2 col-xs-3'>
                            <img src={userPhoto} className="pro-usr-img" />
                        </div>
                        <div className='col-sm-9 col-xs-8' style={{ paddingLeft: 10, }}>
                            <p className='pro-usr-name'> {ival.userName} </p>
                        </div>
                        <div className='col-sm-1 col-xs-1'>
                            {loginType && loginType == "user" && <i class="fa fa-trash-o" style={{ fontSize: 20, color: "red", cursor: "pointer" }} onClick={() => {
                                this.deletePost(ival)
                            }} ></i>}

                        </div>
                    </div>

                    <div className='row' style={{ padding: "10px 0px", borderTop: "1px solid #DDD" }}>
                        <div className='col-sm-1' />
                        <div className='col-sm-10'>
                            <p className='pro-usr-title'> {feedPostData.title} </p>
                        </div>
                        <div className='col-sm-1' />
                    </div>

                    {imageShow && <>
                        <div className='row' style={{ marginTop: 5, paddingTop: 5 }} >
                            <div className='col-sm-1' />
                            <div className='col-sm-10'>
                                <img src={imgPath} className="usr-post-photo" onClick={() => {
                                    this.redirectPage(ival.feedData.id)
                                }} />
                            </div>
                            <div className='col-sm-1' />
                        </div>
                    </>}

                    <div className='row' style={{ paddingTop: 5, }}>
                        <div className='col-sm-1' />
                        <div className='col-sm-10'>
                            <div className='usr-html-content' dangerouslySetInnerHTML={{ __html: descriptionContent }} />
                            {readMoreShow && <span className='read-more' onClick={() => {
                                this.redirectPage(ival.feedData.id)
                            }}>Read More ....</span>}
                        </div>
                        <div className='col-sm-1' />
                    </div>
                </div>
            </>)
        })

        return (<>


            <div className='adminPage-post adminPage '>
                <AdminTopMenu activeTab={"userpost"} />

                <div class="container">
                    <div className='row'>
                        <div className='col-sm-2' />
                        <div className='col-sm-8'>

                            {feedPostArray}

                        </div>
                        <div className='col-sm-2' />
                    </div>
                </div>
            </div>
        </>)
    }

}