import React from "react";
import ReactDiffViewer from 'react-diff-viewer';

export default class JsonDiff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existJson: "",
            newJson: "",
            compare: false,
            formatExistJson: {},
            formatNewJson: {},
        }
        this.sortKeys = this.sortKeys.bind(this);
    }

    sortKeys(x) {
        if (typeof x !== 'object' || !x)
            return x;
        if (Array.isArray(x))
            return x.map(this.sortKeys);
        return Object.keys(x).sort().reduce((o, k) => ({...o, [k]: this.sortKeys(x[k])}), {});
    }

    compareToggle() {
        let { compare, existJson, newJson } = this.state;
        this.setState({
            formatExistJson: JSON.stringify(this.sortKeys(JSON.parse(existJson)), null, '\t'),
            formatNewJson: JSON.stringify(this.sortKeys(JSON.parse(newJson)), null, '\t'),
            compare: !compare 
        })
    }
    render() {
        let { existJson, newJson, compare, formatExistJson, formatNewJson } = this.state;
        return (<React.Fragment>
            <div className="container-fluid json-diff">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h3>Json Compare</h3>
                    </div>
                </div>
                {
                    compare ? <React.Fragment>
                        <div className="row">
                            <div className="col-sm-12 text-right">
                                <button className="btn btn-secondary" onClick={() => this.compareToggle()}>Back</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <ReactDiffViewer 
                                oldValue={formatExistJson} 
                                newValue={formatNewJson} 
                                splitView={true} 
                                showDiffOnly={false} />
                            </div>
                        </div>
                        </React.Fragment> :
                        <div className="row">
                            <div className="col-sm-5 textbox">
                                <textarea className="form-control" style={{ height: '100%' }} placeholder="Enter JSON to compare" onChange={(e) => {
                                    this.setState({
                                        existJson: e.target.value
                                    })
                                } } value={existJson}></textarea>
                            </div>
                            <div className="col-sm-2 textbox text-center compare">
                                <button className="btn btn-success" onClick={() => this.compareToggle()}>Compare</button>
                            </div>
                            <div className="col-sm-5 textbox">
                                <textarea className="form-control" style={{ height: '100%' }} placeholder="Enter JSON to compare" onChange={(e) => {
                                    this.setState({
                                        newJson: e.target.value
                                    })
                                } } value={newJson} />
                            </div>
                        </div>
                }
            </div>
        </React.Fragment>
        );
    }
}
