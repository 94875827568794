import React from 'react';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import logo from '../images/speakup/logo.png';

export default class Header extends React.Component {
    render() {
        return <div className='header'>
            <Link to={MyConstant.keyList.projectUrl+"/speakup"}>
            <img className='header-logo' src={logo} />
            </Link>
        </div>;
    }
}
