import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSignUpData } from "../redux/actions";
import Logo from '../images/Blacktransparent.png'

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                email: "",
                password: "",
                errors: {}
            }
        }
    }

    submit() {
        let { name, email, password, confirm_password } = this.props;
        console.log("name", name);
    }
    render() {
        let { email, password } = this.props;
        let { errors } = this.state;
        return (
            <div className="admin-module">
                <div class="container vcenter">
                    <div class="card card-container">
                        <img id="profile-img" class="img-responsive" src={Logo} />
                        <form class="form-signin">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" name="name" id="name"
                                    className={(typeof errors['name'] != "undefined" && errors['name'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="Name" value={email} onChange={(e) => { this.props.setSignUpData("name", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.name}</div>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email"
                                    className={(typeof errors['email'] != "undefined" && errors['email'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="Email address" value={email} onChange={(e) => { this.props.setSignUpData("email", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.email}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Password</label>
                                <input type="password" name="password" id="password"
                                    className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="***********" value={password} onChange={(e) => { this.props.setSignUpData("password", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.password}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Confirm Password</label>
                                <input type="password" name="confirm_password" id="password"
                                    className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="***********" value={password} onChange={(e) => { this.props.setSignUpData("password", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.password}</div>
                            </div>
                            <button class="btn btn-lg btn-primary btn-block btn-signin" type="button" onClick={() => this.submit()}>Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        name: state.signUpReducer.name,
        email: state.signUpReducer.email,
        password: state.signUpReducer.password,
        confirm_password: state.signUpReducer.confirm_password,
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setSignUpData: (key, value) => dispatch(setSignUpData(key, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);