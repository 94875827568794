import MyConstant from './MyConstant';

const {
    ZiFetchServiceClient, ZiFetchRequest
} = require('../protobuf/speakup/speakup_grpc_web_pb');


let grpcClient = new ZiFetchServiceClient(MyConstant.keyList.speakupGrpcURL, {}, {});

export async function userTrack(page, action, ipAddress) {
    if (!window.location.hostname.includes("localhost")) {
        ipAddress = typeof ipAddress === "undefined" ? localStorage.getItem("ipAddress") : ipAddress;
        if (!ipAddress) {
            ipAddress = await getMyIp();
        }
        let uniqId = localStorage.getItem("uniqId");
        if (!uniqId) {
            uniqId = 'id-' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            localStorage.setItem("uniqId", uniqId)
        }
        let postJson = {
            reqId: uniqId,
            logsJson: {
                page,
                action,
                userAgent: navigator.userAgent,
                ipAddress: ipAddress,
                timestamp: new Date().getTime()
            }
        }
        const request = new ZiFetchRequest();
        request.setDomain('pageAnalytics');
        request.setEvent('captureLogs');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        grpcClient.sayZiFetch(request, {}, async (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request' || response.getMessage() == 'Invalid request') {
                console.warn("Invalid Request");
            } else {
                console.log("Logged")
            }
        });
    }
    return {};
}


export async function getMyIp() {
    return await fetch(MyConstant.keyList.ipURL + "myIP").then((res) => res.json()).then((json) => {
        var ipAddress = json.ip
        return ipAddress;
    });
}
