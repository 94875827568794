import React from 'react';
import './adminStyles.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/Blacktransparent.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyConstant from '../config/MyConstant';



export default class UserLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: {}
        }
    }

    async loginPost() {
        let { email, password } = this.state

        let errors = {}
        if (!email) {
            errors.email = "Enter Email"
        }
        if (!password) {
            errors.password = "Enter Password"
        }
        this.setState({ errors })

        if (Object.keys(errors).length == 0) {
            //loginId : String, password : String
            let postJson = { loginId: email, password: password, };
            console.log("**", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('manage');
            request.setEvent('getUserLogin');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let parseData = JSON.parse(responseData)
                    console.log("responseData", parseData)
                    if (parseData.response == "Success") {
                        localStorage.setItem("UserId", parseData.id.toString());
                        localStorage.setItem("loginUserType", "user");
                        localStorage.setItem("loginUserName", parseData.name.toString());
                        window.location.href = MyConstant.keyList.projectUrl + '/createpost'
                    }
                    else {
                        toast.error('Incorrect email or password.', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }
            });
        }

    }

    render() {
        let { password, email, errors } = this.state
        return (<>

            <div className='usr-login '>
                <div className="usr-login-module">
                    <ToastContainer />
                    <div className="container vcenter">
                        <div className="card card-container">
                            <img id="profile-img" className="img-responsive" src={Logo} />
                            <form className="form-signin">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" id="email"
                                        className={(typeof errors['email'] != "undefined" && errors['email'] != "") ? "custom-invalid  form-control" : " form-control"}
                                        placeholder="Email address" value={email} onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }} />
                                    <div className="custom-invalid-feedback">{errors.email}</div>
                                </div>
                                <div className="form-group mb-4">
                                    <label>Password</label>
                                    <input type="password" name="password" id="password"
                                        className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                        placeholder="***********" value={password} onChange={(e) => { this.setState({ password: e.target.value }) }} />
                                    <div className="custom-invalid-feedback">{errors.password}</div>
                                </div>
                                <button className="btn btn-lg btn-primary btn-block btn-signin" type="button" onClick={() => this.loginPost()}>Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

}