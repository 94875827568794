import React, { useState } from 'react';

import Slider from '../components/Slider';
import { withRouter } from 'react-router-dom';
import { getSlider } from './SliderFiles';

function Slide(props) {
    let { id } = props.match.params
    
    return <div className='slide-page'>
        <Slider slider={getSlider(id)} />
    </div>;
}

export default withRouter(Slide)