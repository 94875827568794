import React from 'react';
import Config from "../../helper/Config";
import { Link } from 'react-router-dom';
import O_photoCropped from '../../images/O_photoCropped.jpg';
import { Style } from "react-style-tag";
import coronaimage2 from '../../images/coronaimage_1.jpg';
import Umedme from '../../images/footer/umedme.png'
import umedme_bw from '../../images/footer/umedme_gray.png'
import Gis from '../../images/gisone.png'
import Tilli from '../../images/tilli_new.png';
import tilli_bw from '../../images/tilli_bw_new.png';
import climate_crop from '../../images/footer/climate_c.png';
import LNOB_crop from '../../images/footer/Lnop_c.png';
import Speakup_Logo from '../../images/footer/logo_Speakup.png'



const projectUrl = Config.projectUrl;

class FooterV2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      privacy: 0,
      privacyRender: false
    }
  }

  async componentDidMount() {
    let check = await localStorage.getItem("accept");
    if (!check) {
      this.setState({
        privacy: 1,
        privacyRender: true
      })
    }
  }

  menuClick() {
    window.scrollTo(0, 0);
  }

  render() {
    let { page } = this.props;
    let year = new Date().getFullYear();
    return (
      <React.Fragment>


        <Style>
          {`

.p-tag-font{
    font-family:Helvetica-Regular;
    font-size: 16px; 
    text-align: center;
    color:white
  }
.p-tag-font1{
    font-family:Helvetica-Regular;
    font-size: 16px; 
    text-align: center;
    color:white
  }
  .span-tag{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white
  }
  .footerTextcontent
  {
    margin-top: 5px;  
    text-align: center;
    line-height: 1.79em;
  }
  .blackbar {
        min-height: 130px
  }
  @media screen and (max-width:600px) {
    .blackbar {
        min-height: 135px
    }
    .p-tag-font{
        font-family:Helvetica-Regular;
        font-size:16px; 
        text-align: center;
        color:white
     }
     .p-tag-font1{
        font-family:Helvetica-Regular;
        font-size:${page === "mission" ? 14 : 13}px; 
        text-align: center;
        color:white
    }
     .span-tag {
        font-size:18px !important; 
     }
     .footerTextcontent {
        margin-top: 5px;  
      }
  }
  .image_1:hover{
    background-image: url(${coronaimage2});
    width:100%;
    filter: initial;
    
   }
   .image_2{
    background: url(${umedme_bw});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
     display: block;
     margin-left: auto;
     margin-right: auto;
   }
   
   .image_2:hover{
      background-image: url(${Umedme});
      width:100%;
      filter: initial;
   }
   .image_3{
    background: url(${Speakup_Logo});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
     filter: grayscale(100%);
     display: block;
     margin-left: auto;
     margin-right: auto;     
   }
   .image_3:hover{
    background-image: url(${Speakup_Logo});
    width:100%;filter: initial;
   }
   .image_4{
    background: url(${Gis});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
     display: block;
     margin-left: auto;
     margin-right: auto;
   }
   .image_4:hover{
    background-image: url(${Gis});
    width:100%;filter: initial;
   }
   
   .image_5{
    background: url(${tilli_bw});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
     display: block;
     margin-left: auto;
     margin-right: auto;
   }
   .image_5:hover{
    background-image: url(${Tilli});
    width:100%;filter: initial;
   }

   .image_6{
    background: url(${climate_crop});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
     display: block;
     margin-left: auto;
     margin-right: auto;
   }
   
   .image_6:hover{
      background-image: url(${climate_crop});
      width:100%;
      filter: initial;
   }
   .image_7{
    background: url(${LNOB_crop});
    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
     display: block;
     margin-left: auto;
     margin-right: auto;
   }
   
   .image_7:hover{
      background-image: url(${LNOB_crop});
      width:100%;
      filter: initial;
   }
  @media screen and (min-width:600px && max-width:900px) {
      .blackbar {
        min-height: 135px
      }
    .footerTextcontent{
        margin-top: 5px;  
    }
  }
  .come{
    width: 100%;
    height: 100px;
    position:fixed;
    animation:comeMove 5s;
    bottom:-2px;
  }
  @keyframes comeMove {
    from{bottom:-150px;opacity:0} 
    to{bottom:-2px;opacity:1}
  }

  .go{
    width: 100%;
    height: 100px;
    position:fixed;
    animation:goMove 5s;
  }
  @keyframes goMove {
    to{bottom:-150px;opacity:0} 
    from{bottom:-2px;opacity:1}
  }
  `}

        </Style>
        <div className='grey-bg py-2'>
          <div className="row mx-0">
            <div className="col-sm-12 text-center pb-2 share-text">
              Share
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-sm-12 text-center" id="social-icons">
              <div className="mx-2 d-inline ">
                <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location}>
                  <i className="fa fa-facebook-f"></i>
                </a>
              </div>
              {/* <div className="mx-2 d-inline ">
                <a target="_blank" href={"https://twitter.com/intent/tweet?text=Teqbahn&url=" + window.location}>
                  <i className="fa fa-twitter"></i>
                </a>
              </div> */}
              <div className="mx-2 d-inline ">
                <a target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + window.location}>
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
              <div className="mx-2 d-inline ">
                <a href={"mailto:?subject=Teqbahn&body=" + window.location} >
                  <i className="fa fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="version-2-footer row blackbar" style={{ margin: 0, backgroundColor: 'black', position: 'absolute', backgroundRepeat: "no-repeat", width: '100%', backgroundSize: 'cover', padding: '10px' }}>
          <div className="col-sm-4 d-flex">
            <div className='zoom footer-logo'>
              {
                page === "mission" && <a href="" target="" title="">
                  <div className="image_6" style={{}}></div>
                </a>
              }

            </div>
          </div>
          <div className="col-sm-4">
            <p className={"footerTextcontent"}  >
              <p className={`p-tag-font p-tag-font1 ${page}`}>
                Teqbahn is registered as Teqbahn Foundation <br />
                A 501(c)(3) organization. Tax ID: 86-3034752 <br />
                11 creekside court,<br />
                Secaucus, New Jersey 07094<br />
                email: contact@teqbahn.com
              </p>
              <span className="p-tag-font p-tag-font1" style={{}} >©{year} by Teqbahn</span>

              <Link onClick={() => this.menuClick()} className="p-tag-font p-tag-font1" to={Config.projectUrl + "/privacy-policy"} >&nbsp;| Privacy&nbsp;Policy</Link>&nbsp;&nbsp;
            </p>
          </div>
          <div className="col-sm-4 d-flex">
            <div className='zoom footer-logo'>
              {
                page === "mission" && <a href="" target="" title="">
                  <div className="image_7" style={{}}></div>
                </a>
              }
            </div>
          </div>
        </div>

        {
          this.state.privacyRender && <div className={this.state.privacy ? 'come set-height' : 'go set-height'}
            style={page === "home" ? { backgroundImage: `url(${O_photoCropped})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' } : { 'background': '#fff' }}
          >
            <div className='row' style={{ padding: 10 }}>
              <div className='col-sm-1' />
              <div className='col-sm-8' style={{ marginTop: 40 }}>
                <p className="privacy-policy-text"> We have updated our <a style={{ color: '#000', textDecoration: 'underline' }} href={projectUrl + "/privacy-policy"}>Privacy Policy</a>. Do you agree to allow cookies?</p>
              </div>
              <div className='col-sm-2' style={{ marginTop: 30 }}>
                <div style={{ backgroundColor: '#FFF', textAlign: 'center' }}>
                  <a href='javascript:void(0)' style={{ backgroundColor: '#FFF', textAlign: 'center', textDecoration: 'none' }} onClick={() => {
                    localStorage.setItem("accept", "1");
                    this.setState({ privacy: 0 })
                  }}>
                    <p className={page === "home" ? "privacy-policy-link" : "privacy-policy-link-about"} > OK, I AGREE </p>
                  </a>
                </div>
              </div>
              <div className='col-sm-1' />
            </div>
          </div>
        }

      </React.Fragment>
    );
  }
}

export default FooterV2;
