import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Logo from './images/Blacktransparent.png';
import Config from "./helper/Config";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    menuClick() {
        window.scrollTo(0, 0);
    }


    render() {
        let { page, style } = this.props;
        console.log(page)
        var menuTextColorClass="nav-link"
        if(page === "TPRHome")
        {
            menuTextColorClass ="nav-link whiteLable" 
        }
        return (
            <React.Fragment>
                <nav id="menu" style={style} className="menu-fixed">
                    <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className={`nav navbar-nav navbar-right + ${page==="TPRHome"? "tprheader" : ""}`}>
                            <li className={page === "home" ? "nav-item px-2 active" : "nav-item px-2"} >
                                <Link to={Config.projectUrl + "/"} className={menuTextColorClass} onClick={() => this.menuClick()}>HOME</Link>
                            </li>
                            <li className={page === "TPRHome" ? "nav-item px-2 active" : "nav-item px-2"} >
                                <Link to={Config.projectUrl + "/tprhome"} className={menuTextColorClass} onClick={() => this.menuClick()}>
                                    <div className="tooltip-container">
                                        TPR
                                        <div className="tooltip">Technology Process Re-engineering</div>
                                    </div>
                                </Link>
                            </li>
                            <li className={page === "csit" ? "nav-item px-2 active" : "nav-item px-2"} >
                                <Link to={Config.projectUrl + "/csit"} className={menuTextColorClass} onClick={() => this.menuClick()}>
                                    <div className="tooltip-container">
                                        CSIT
                                        <div className="tooltip">Carbon Sensitive Information Technology</div>
                                    </div>
                                </Link>
                            </li>
                            <li className="dropdown">
                                <a href="#" className={`dropdown-toggle nav-link ${menuTextColorClass}`} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">TeqED <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to={Config.projectUrl + "/TeqEd"} onClick={() => this.menuClick()}>TeqEd</Link>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/blog"} onClick={() => this.menuClick()}>BLOG</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}