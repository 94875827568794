import React from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import O_photoCropped from '../images/O_photoCropped.jpg';
import { Style } from "react-style-tag";
import Logo from '../images/BlackTransparentNew.png'
import teqbahn from '../images/blog/teqbahn.png'
import blog1 from '../images/blog/blog1.png'
import blog2 from '../images/blog/blog2.png'
import blog3 from '../images/blog/blog3.png'
import blog4 from '../images/blog/blog4.png'
import blog5 from '../images/blog/blog5.png'
import blog6 from '../images/blog/blog6.png'
import blog7 from '../images/blog/blog7.png'
import blog8 from '../images/blog/blog8.png'
import blog9 from '../images/blog/blog9.jpeg'
import { Link } from "react-router-dom";
import Config from '../helper/Config';

let intervalId;
export default class BlogV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [
                {
                    image: blog1,
                    title: "Tilli, a Teqbahn collaboration, among the three global finalists at The Future Play Design Challenge by CoC Playful Minds and LEGO Ventures.",
                    name: "Tilli",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "4 min read",
                    column: 7,
                    url: "https://medium.com/@Teqbahn/tilli-a-teqbahn-collaboration-among-the-three-global-finalists-at-the-future-play-design-8ec8765c47be"
                },
                {
                    image: blog2,
                    title: "Impact on Families",
                    name: "Family Healthcare",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "7 min read",
                    column: 5,
                    url: "https://medium.com/@Teqbahn/impact-on-families-6ece82a1c9e8"
                },
                {
                    image: blog3,
                    title: "Impact on Healthcare Providers",
                    name: "Healthcare",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "7 min read",
                    column: 5,
                    url: "https://medium.com/@Teqbahn/impact-on-healthcare-providers-79b62dd8b89"
                },
                {
                    image: blog4,
                    title: "It’s All About Who? Me?",
                    name: "Personal Health Record",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "5 min read",
                    column: 7,
                    url: "https://medium.com/@Teqbahn/its-all-about-who-me-32ef90cf761c"
                },
                {
                    image: blog5,
                    title: "Introducing Umed.Me. A Teqbahn Collaboration.",
                    name: "Health Care Reform",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "4 min read",
                    column: 7,
                    url: "https://medium.com/@Teqbahn/introducing-umed-me-a-teqbahn-collaboration-19b6d39d067a"
                },
                {
                    image: blog6,
                    title: "Tools Available For Providers and Patients",
                    name: "Healthcare Innovations",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "5 min read",
                    column: 5,
                    url: "https://medium.com/@Teqbahn/tools-available-for-providers-and-patients-f0d88d0e5035"
                },
                {
                    image: blog7,
                    title: "How caregiving presents an additional challenge for women",
                    name: "Caregiving",
                    nameImage: teqbahn,
                    date: "2022-07-02",
                    readTime: "4 min read",
                    column: 5,
                    url: "https://medium.com/@Teqbahn/how-caregiving-presents-an-additional-challenge-for-women-1a31dac67683"
                },
                {
                    image: blog8,
                    title: "Meet Tilli. A Teqbahn Collaboration.",
                    name: "Social Emotional Learning",
                    nameImage: teqbahn,
                    date: "2021-09-20",
                    readTime: "3 min read",
                    column: 7,
                    url: "https://medium.com/@Teqbahn/meet-tilli-a-teqbahn-collaboration-38707525ec5c"
                },
                {
                    image: blog9,
                    title: "Welcome to Teqbahn",
                    name: "Leadership",
                    nameImage: teqbahn,
                    date: "2021-09-20",
                    readTime: "4 min read",
                    column: 7,
                    url: "https://medium.com/@Teqbahn/a-social-impact-accelerator-b66db40e7ae8"
                },
            ]
        }
    }
    componentDidMount() {
        analyticsUserTrack("Blog", "Landing");
    }
    dateFormatChange(date) {
        date = new Date(date);
        const month = ["Jan", "Feb", "Mar", "Apri", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let dateYear = "";

        let currentDate = new Date();
        if (date.getFullYear() !== currentDate.getFullYear()) {
            dateYear = "," + date.getFullYear()
        }
        return month[date.getMonth()] + " " + date.getDate() + dateYear;
    }

    render() {
        let width = window.innerWidth;
        let { content } = this.state;
        let { bannerState } = this.props;
        return <div>
            <Style>
                {`
                .header {
                    padding: 10px;
                    color: #f1f1f1;
                    position: fixed;
                }
                .sticky {
                    padding: 10px;
                    top: 0;
                    z-index:2;
                    background-image: url(${O_photoCropped});
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                  @media (max-width: 600px) {
                    .logoImage{
                        width:300px !important;
                    }
                  }
                  .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  } 
               `}
            </Style>
            <div className="img_full_view blog">
                <div className="header img_full_view sticky" style={{ width: '100%', paddingTop: bannerState ? (window.screen.width < 768 ? 145 : 30) : 0 }}>
                    <div className="col-12" style={{ minHeight: 120, width: '100%', padding: 20 }}>
                        <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                            <img className="logoImage" src={Logo} style={{ width: 50 * 7, paddingTop: bannerState ? 30 : 0 }} />
                        </Link>
                    </div>
                </div>
                <Header page="blog" />
            </div>
            <div className='blog-content container'>
                <div className='blog-box-container'>
                    <div className='row'>
                        {
                            content.map((item, index) => {
                                return <div className={`col-sm-${item.column} blog-box`} key={index}>
                                    <a href={item.url} className={`blog-box-url`} style={{ backgroundImage: `url(${item.image})` }}>
                                        <div className='blog-box-gray' />
                                        <div className='blog-box-relative'>
                                            <div className={`blog-box-content ${(item.column === 7 && width > 800) ? "block-box-right" : ""}`}>
                                                <h3>{item.title.length > 65 ? item.title.substring(0, 65) + "..." : item.title}</h3>
                                                <div className='blog-box-user'>
                                                    <div className='blog-box-img'>
                                                        <img className='img-responsive' src={item.nameImage} />
                                                    </div>
                                                    <div className='blog-box-info'>
                                                        <div>{item.name}</div>
                                                        <div>{this.dateFormatChange(item.date)} . {item.readTime}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}
