import React from 'react';
import Header from './Header';
import Logo from './images/BlackTransparentNew.png';
import { Style } from "react-style-tag";
import O_photoCropped from './images/O_photoCropped.jpg';
import O_photo from './images/O_photo.jpg';
import { Background } from 'react-parallax';
import Config from './helper/Config';

const projectUrl = Config.projectUrl;


class TPR extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceWidth: 0,
            imgWidth: 0,
            email: "",
            password: "",
            errors: {},
            userName: "tpr@teqbahn.com",
            userPassword: "123456"
        }
    }
    submit(event) {
        event.preventDefault();

        let { email, password, userName, userPassword } = this.state
        let mail = userName
        let pass = userPassword
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let errors = {}
        if (email !== mail) {
            errors["email"] = "please enter valid user name";
        }
        else if (!filter.test(email)) {
            errors["email"] = "please enter valid email"
        }
        if (password !== pass) {
            errors["password"] = "please enter password";
        }
        else if (password == "") {
            errors["password"] = "please enter password"
        }
        if (Object.keys(errors).length > 0) {
            this.setState({ errors })
        }
        else {
            this.setState({ errors: {} })
            window.location = projectUrl + "/tprhome"
        }
    }
    render() {
        let { email, password, errors } = this.state;


        return (
            <div>
                <Style>
                    {`
                .header {
                    padding: 10px;
                    color: #f1f1f1;
                    position: fixed;
                }
                .sticky {
                    padding: 10px;
                    top: 0;
                    z-index:2;
                    background-image: url(${O_photoCropped});
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
               `}
                </Style>
                <div className="img_full_view" >
                    <div className="header img_full_view sticky" style={{ width: '100%' }}>
                        <div className="col-12" style={{ minHeight: 120, width: '100%', padding: 20 }}>
                            <img className="logoImage" src={Logo} style={{ width: 50 * 7 }} />
                        </div>
                    </div>
                    <Header page="TPR" />
                </div>
                <div className='tpr-content'>
                    <div className='tpr-innerbox'>
                        <div className='formStyle'>
                            <form className='forminside'>
                                <h3>Login TPR</h3>
                                <div className="password_col">
                                    <label for="username" className="user_label">User Name</label>
                                    <input type="text" className="form-control user" value={email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                </div>
                                {errors["email"] && <span style={{ color: "red" }}>{errors["email"]}</span>}
                                <div className="password_col">
                                    <label className="pass_label">Password</label>
                                    <input type="password" className="pass form-control"
                                        value={password}
                                        onChange={(e) => { this.setState({ password: e.target.value }) }}
                                    />
                                </div>
                                {errors["password"] && <span style={{ color: "red" }}>{errors["password"]}</span>}

                                <div className="d-grid">
                                    <button type="submit" className="btn btn-md btn-info" onClick={(e) => { this.submit(e) }}>Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TPR;