import React from 'react';
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css'
import '../slides.css'
import Jpg1 from './gisnew/1.jpg'
import Jpg2 from './gisnew/2.jpg'
import Jpg3 from './gisnew/3.jpg'
import Jpg4 from './gisnew/4.jpg'
import Jpg5 from './gisnew/5.jpg'
import Jpg6 from './gisnew/6.jpg'
import Jpg7 from './gisnew/7.jpg'
import Jpg8 from './gisnew/8.jpg'
import Jpg9 from './gisnew/9.jpg'
import Jpg10 from './gisnew/10.jpg'
import Jpg11 from './gisnew/11.jpg'
import Jpg12 from './gisnew/12.jpg'
import Jpg13 from './gisnew/13.jpg'
import Jpg14 from './gisnew/14.jpg'
import Jpg15 from './gisnew/15.jpg'
import Jpg16 from './gisnew/16.jpg'

class SlideGIS extends React.Component {

    render() {

        return (
            <div>
                <div style={{padding: 20}}>
                    <a href={'https://teqbahn.com/'} style={{color: 'blue'}}>Home</a>
                </div>
                <Slide easing="ease" autoplay={false}>
                    <div className="each-slide">
                        <img src={Jpg1} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg2} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg3} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg4} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg5} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg6} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg7} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg8} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg9} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg10} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg11} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg12} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg13} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg14} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg15} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <a href="https://www.globalinclusionstrategies.com/" target={'_blank'}>
                        <img src={Jpg16} alt="" style={{width: '100%', height: '100%', paddingLeft: 50, paddingRight: 50}}/>
                        </a>
                    </div>
                </Slide>
            </div>
        );
    }
}

export default SlideGIS
