import {
  SET_ADMIN_LOGIN,
  RESET_ADMIN_LOGIN,
} from '../constants/actionTypes';

const initialState = {
  email: '',
  password: '',
  login_type: '',
  passwordVisible: true,
  errors_email: '',
  errors_password: '',
};

const adminLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_LOGIN: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case RESET_ADMIN_LOGIN: {
      return {
        ...state,
        success: false,
        error: false,
        displayMessage: '',
        email: '',
        password: '',
        passwordVisible: true,
        errors_email: '',
        errors_password: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default adminLoginReducer;
