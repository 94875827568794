import React from 'react';
import { Link, } from "react-router-dom";
import MyConstant from '../config/MyConstant';
import  Blacktransparent from "../images/Blacktransparent.png"


let projectUrl = MyConstant.keyList.projectUrl

export default class AdminTopMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    async componentDidMount() {

    }

    async capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    render() {
        let { activeTab, } = this.props
        var loginType = localStorage.getItem("loginUserType");
        if (!loginType) {
            loginType = ""
        }

        let showType = loginType

        if (loginType == "user") {
            var userName = localStorage.getItem("loginUserName");
            showType = userName
        }

        if (showType) {
            showType = showType[0].toUpperCase() + showType.slice(1);
        }
        return (<>
            <nav className="navbar navbar-inverse">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        {/* <a className="navbar-brand admin-text-bold" >{loginType} </a> */}
                        <img src={Blacktransparent} style={{width:250,marginTop:20}} />
                    </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="nav navbar-nav">
                            <li className={activeTab == "createpost" ? "active" : ""}>
                                <Link to={projectUrl + "/createpost"}>Creat Post</Link>
                            </li>

                            {loginType == "admin" ? <>
                                <li className={activeTab == "adduser" ? "active" : ""}>
                                    <Link to={projectUrl + "/adduser"}>Add User</Link>
                                </li>
                                <li className={activeTab == "userlist" ? "active" : ""}>
                                    <Link to={projectUrl + "/userlist"}>User List</Link>
                                </li>
                            </> : null}

                            {loginType == "user" && <>
                                <li className={activeTab == "userpost" ? "active" : ""}>
                                    <Link to={projectUrl + "/userpost"}>My Post</Link>
                                </li></>}


                            {/* <li className={activeTab == "viewpost" ? "active" : ""} >
                                <Link to={projectUrl + "/feedpost"}>View Post</Link></li> */}
                        </ul>
                        <ul className="nav navbar-nav navbar-right">

                            <li><a className=''>
                                <span className="glyphicon glyphicon glyphicon-user"></span>{showType}</a>
                            </li>

                            <li><a className='cursor-style' onClick={() => {

                                let redirectPage = ""

                                if (loginType == "admin") {
                                    redirectPage = "adminlogin"
                                }
                                else if (loginType == "user") {
                                    redirectPage = "userlogin"
                                }

                                localStorage.removeItem("UserId");
                                localStorage.removeItem("loginUserType");
                                window.location.href = MyConstant.keyList.projectUrl + '/' + redirectPage
                            }}>
                                <span className="glyphicon glyphicon-log-in"></span> Log Out</a></li>


                        </ul>
                    </div>
                </div>
            </nav>
        </>)
    }

}