const initialState = {
  success: false,
  name: '',
  email: '',
  password: '',
  confirm_password: ''
};

const signUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIGNUP': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'RESET_SIGNUP': {
      return {
        ...state,
        name: '',
        email: '',
        password: '',
        confirm_password: ''
      };
    }
    default: {
      return state;
    }
  }
};

export default signUpReducer;
