import {SET_ADMIN_AUTH, RESET_ADMIN_AUTH} from '../constants/actionTypes';

const initialState = {
  isAdminLoggedIn: '0',
  loggedSession: '',
};

const authAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_AUTH: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case RESET_ADMIN_AUTH: {
      return {
            ...state,
            isAdminLoggedIn: '0',
            loggedSession: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default authAdminReducer;
