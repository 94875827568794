import React from 'react';
import EditorContent from "./EditorContent"
import { v4 as uuidv4 } from 'uuid';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import MyConstant from '../config/MyConstant';
import './adminStyles.css';
import AdminTopMenu from "./AdminTopMenu"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginStatusCheck } from './Common';






export default class CreatePost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            images: [],
            fileObj: {},
            userId: "",
            errorObject: {},
            userDetails: [],

        }
    }

    async componentDidMount() {
        document.body.style.background = "#DCDCDC";

        loginStatusCheck()

        let getUserId = await localStorage.getItem("UserId");
        if (getUserId) {
            this.setState({ userId: getUserId })
        }

        let getLoginType = await localStorage.getItem("loginUserType");

        if (getLoginType && getLoginType == "admin") {
            this.getUserDetails()
        }


    }

    getUserDetails() {
        //auth: String
        let postJson = { auth: "Teqbahn@321#", };
        console.log("**", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('manage');
        request.setEvent('getAllUsersList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let parseData = JSON.parse(responseData)
                console.log("responseData", parseData)
                this.setState({ userDetails: parseData.result })

            }
        });

    }

    async postCreate() {
        let { description, fileObj, title, userId } = this.state

        let errorObject = {}

        if (!title) {
            errorObject.title = "Enter Title"
        }
        if (!description) {
            errorObject.description = "Enter Title"
        }
        this.setState({ errorObject })

        var loginType = localStorage.getItem("loginUserType");
        let userType = ""
        if (loginType) {
            userType = loginType
        }



        // userId: String, title: String, coverImage:String, description: String createdBy:String
        if (Object.keys(errorObject).length == 0 && userId) {
            let postJson = { userId: userId, title: title, coverImage: fileObj.fileName ? fileObj.fileName : "", description: description, createdBy: userType };
            console.log("**", postJson)
            if (Object.keys(fileObj).length != 0) {
                let imageRespose = await this.filePostApi()
                console.log("imageRespose", imageRespose)
            }

            const request = new ZiFetchRequest();
            request.setDomain('user');
            request.setEvent('createFeed');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    let parseData = JSON.parse(responseData)
                    console.log("responseData", parseData)
                    if (parseData.response == "Success") {
                        toast.success('Create Post !.', {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        }

    }


    async filePostApi() {
        let { fileObj } = this.state
        let dataJson = fileObj
        if (dataJson != "{}" && Object.keys(dataJson).length > 0) {
            var i = 0
            const postFileUpload = new FormData();
            postFileUpload.append('file' + (i + 1), dataJson.file);
            postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
            postFileUpload.append('processType' + (i + 1), dataJson.processType);
            postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
            //  postFileUpload.append('userId', dataJson.userId);
            //  alert(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName)

            let imgres = await fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName, {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            }).then(function (response) {
                // console.log("response_1", response)
                return response.status == 200 ? true : false
            }
            )
            console.log("imgres", imgres)
            return imgres
        }
    }

    async selectOnchange(value) {
        this.setState({ userId: value })
    }

    render() {
        let { title, description, fileObj, errorObject, userDetails } = this.state
        let getLoginType = localStorage.getItem("loginUserType");

        let selectOptions = []
        if (getLoginType && getLoginType == "admin") {
            userDetails.map((ival) => {
                selectOptions.push(<option value={ival.userId}>{ival.fullName}</option>)
            })
        }

        return (<>
            <div className='adminPage'>
                <AdminTopMenu activeTab={"createpost"} />
                <ToastContainer />
                <div class="container">
                    <h2 style={{ textAlign: "center" }}>Create Post</h2>

                    {getLoginType && getLoginType == "admin" && <div class="form-group">
                        <div className='row'>
                            <div className='col-3 col-sm-3' />
                            <div className='col-6 col-sm-6'>
                                <label >User Select:</label>
                                <select className="form-control" name="login_type" onChange={(e) => {
                                    console.log("e", e.target.value)
                                    this.selectOnchange(e.target.value)
                                }}
                                >
                                    <option value="">Select Type</option>
                                    {selectOptions}

                                </select>
                            </div>
                            <div className='col-3 col-sm-3' />
                        </div>
                    </div>}

                    <div class="form-group">
                        <div className='row'>
                            <div className='col-3 col-sm-3' />
                            <div className='col-6 col-sm-6'>
                                <label >Title:</label>
                                <input type="text" class="form-control" id="email" placeholder="Enter Name"
                                    value={title} onChange={(e) => {
                                        this.setState({ title: e.target.value })
                                    }} />
                                <span className='error-style'>{errorObject.title}</span>
                            </div>
                            <div className='col-3 col-sm-3' />
                        </div>
                    </div>


                    <div class="form-group">
                        <div className='row'>
                            <div className='col-3 col-sm-3' />
                            <div className='col-6 col-sm-6'>
                                <label >Description:</label>
                                <EditorContent text={description} onChangeText={(value) => {
                                    this.setState({ description: value })

                                }} />
                                <span className='error-style'>{errorObject.description}</span>
                            </div>
                            <div className='col-3 col-sm-3' />
                        </div>
                    </div>

                    <div class="form-group">
                        <div className='row'>
                            <div className='col-3 col-sm-3' />
                            <div className='col-6 col-sm-6'>
                                <label >Image:</label>
                                <input type="file" onChange={async (event) => {
                                    var files = event.target.files;
                                    var length = files.length;
                                    var urlfile = ''
                                    if (length > 0) {
                                        for (var i = 0; i < length; i++) {
                                            var fileUrl = URL.createObjectURL(files[i]);
                                            var file = files[i];
                                            var filename = file.name;
                                            var ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                                            // if(file.type == "image/jpeg"){
                                            //     ext = "jpeg";
                                            // }
                                            urlfile = fileUrl
                                            var uid = uuidv4();
                                            var fileObj = {
                                                file: file,
                                                fileUrl: fileUrl,
                                                fileName: uid + "." + ext,
                                                docsId: uid,
                                                processType: "coverimage",
                                                fileType: "image"
                                            };
                                            console.log("fileObj", fileObj)
                                            this.setState({ fileObj, fileType: ext })
                                        }
                                    }
                                }
                                }
                                />
                            </div>
                            <div className='col-3 col-sm-3' />

                            <div className='col-4 col-sm-4' />
                            <div className='col-4 col-sm-4'>
                                {Object.keys(fileObj).length != 0 ?
                                    <img src={fileObj.fileUrl} class="img-rounded" alt="Responsive image" style={{ width: 200, }} /> : null}
                            </div>
                            <div className='col-4 col-sm-4' />

                        </div>
                    </div>


                    <div className='row' style={{ textAlign: "center" }}>
                        <button class="btn btn-success" onClick={() => {
                            this.postCreate()
                        }}>Submit</button>
                    </div>

                </div>

            </div>
        </>)
    }

}