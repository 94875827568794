import React from 'react';
import './adminStyles.css';
import AdminTopMenu from "./AdminTopMenu"
import Pagination from './Pagination';
import { pageNumbers } from './Common';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import { loginStatusCheck } from './Common';
import MyConstant from '../config/MyConstant';






export default class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: [],
            pagination: [],
            prev: false,
            next: false,
            noPagesLimit: 1, processing: false
        }
        this.movePage = this.movePage.bind(this);
    }

    componentDidMount() {
        loginStatusCheck()
        document.body.style.background = "#DCDCDC";
        this.getAllUserList();
    }

    getAllUserList() {
        let { noPagesLimit } = this.state;
        let postJson = { pageLimit: 10, noOfPage: noPagesLimit, totalResult: 10, auth: "Teqbahn@321#", }
        const request = new ZiFetchRequest();
        request.setDomain('manage');
        request.setEvent('getUsersList');
        request.setMessage(JSON.stringify(postJson));
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() === 'Invalid Request' || response.getMessage() === 'Invalid request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseMsg = JSON.parse(response.getMessage());
                responseMsg = JSON.parse(responseMsg)
                if (responseMsg && responseMsg.result) {
                    let responseData = responseMsg.result
                    let next = false;
                    let prev = false;
                    let totalResult = responseMsg.totalResult
                    let currentResult = noPagesLimit * 10;
                    if (currentResult < totalResult) {
                        next = true;
                    }
                    if (noPagesLimit > 1) {
                        prev = true;
                    }

                    let pageCount = Math.ceil(totalResult / 10);
                    let pagination = pageNumbers(pageCount, noPagesLimit, 5);
                    this.setState({ userDetails: responseData, prev, next, processing: false, pagination: pagination });
                }
            }
        });
    }


    movePage(page) {
        let pageNo = page
        if (page === "next") {
            if (this.state.next) {
                pageNo = this.state.noPagesLimit + 1;
            }
        } else if (page === "prev") {
            if (this.state.prev) {
                pageNo = this.state.noPagesLimit - 1;
            }
        }

        if (pageNo !== "next" && pageNo !== "prev") {
            this.setState({
                noPagesLimit: pageNo,
                processing: true
            }, () => {
                this.getAllUserList();
            })
        }
    }
    userPostView(userId) {
        window.open(MyConstant.keyList.projectUrl + '/userpost/' + userId)
    }
    render() {
        let { pagination, noPagesLimit, prev, next, userDetails } = this.state
        return (<>
            <div className='adminPage'>
                <AdminTopMenu activeTab={"userlist"} />
                <div class="container">
                    <h2 style={{ textAlign: "center" }}>User List</h2>
                    <table className="table table-theme table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">View Feed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userDetails && userDetails.map((ival, i) => {
                                return (<>
                                    <tr key={i.toString()}>
                                        <td>{i + 1}</td>
                                        <td>{ival.fullName}</td>
                                        <td>{ival.email}</td>
                                        <td>{ival.mobileNumber}</td>
                                        <td>  <button type="button" class="btn btn-link" onClick={() => {
                                            this.userPostView(ival.userId)
                                        }} >View</button></td>

                                    </tr></>)
                            })}

                        </tbody>
                    </table>
                    <Pagination
                        pagination={pagination}
                        prev={prev}
                        next={next}
                        currentPage={noPagesLimit}
                        movePage={(type) => {
                            this.movePage(type)
                        }} />

                </div>
            </div>
        </>)
    }

}