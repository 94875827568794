import {
  SET_LOGIN,
  RESET_LOGIN,
  SET_SIGNUP,
  RESET_SIGNUP,
  SET_ADMIN_LOGIN,
  RESET_ADMIN_LOGIN,
  SET_ADMIN_AUTH,
  RESET_ADMIN_AUTH
} from '../constants/actionTypes';

export const setLoginData = (key, value) => ({
  type: SET_LOGIN,
  key: key,
  value: value,
});
export const setAdminLoginData = (key, value) => ({
  type: SET_ADMIN_LOGIN,
  key: key,
  value: value,
});

export const reSetLoginData = () => ({
  type: RESET_LOGIN,
});

export const reSetAdminLoginData = () => ({
  type: RESET_ADMIN_LOGIN,
});

export const setSignUpData = (key, value) => ({
  type: SET_SIGNUP,
  key: key,
  value: value,
});

export const reSetSignUData = () => ({
  type: RESET_SIGNUP,
});


export const setAdminAuthData = (key, value) => ({
  type: SET_ADMIN_AUTH,
  key: key,
  value: value,
});

export const reSetAdminAuthData = () => ({
  type: RESET_ADMIN_AUTH,
});