import React from 'react';
import { Style } from "react-style-tag";
import Logo from './images/ventures/BlackonTransparent.png'
import coronaimage_bw from './images/coronaimage2.png';
import Header from './version2/component/Header';
import slide1img5 from "./images/version3/slider1/5.jpg";
import Config from './helper/Config';
import { Link } from "react-router-dom";

class Ventures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            mainTextAlignClass: "text-right"
        };
    }

    componentDidMount() {

    }

    render() {
        let { bannerState } = this.props;
        return (
            <React.Fragment>
                <Style>
                    {`
                    .lastRow {
                        height: 600px;
                        padding-bottom: 50px;
                        padding-top: 40px;
                    }
                    .lastRow1 {
                        padding-top: 100px;
                        padding-left:50px
                    }
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
                .threeViewB {
                    paddingBottom : 30px
                }
                .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 200px; padding-right: 200px
                }
                .nowrap {
                    white-space: nowrap !important;
                }
                .sticky {
                  padding: 10px;
                  top: 0;
                  z-index:1;
                  background-size: cover;
                  background-repeat: no-repeat
                }
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                  
                  .boldcontent{
                        text-align: center;
                        font-family: Helvetica-Regular;
                        font-size:30px;font-weight:bold;
                        white-space: nowrap !important;
                      }
                   
                .p-tag{
                    font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                 }
                 .div_bg_img {
                    padding: 10px;
                    top: 0;
                    z-index:1;
                    background-color:#d4d4d4;
                    box-shadow:0px 5px 4px #888788;	
                  }
                
                .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  }                    
                .div_height{
                    height: 150px;
                    }
                   .image_1{
                    background: url(${coronaimage_bw});
                   width:100%;
                   height:100%;
                   background-repeat:no-repeat;
                   background-size:contain ;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    /*manage top*/
                    margin-top:45px
}
                   }  
                   
                  @media screen and (min-width:900px) {
                    .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                       }
                       
                       .lastRow {
                        height: 600px;
                    }
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                  
                      
                  }
                
                @media screen and (max-width:1000px) {
               
                       .threeViewB {
                        padding-bottom : 40px
                    }
                    
                    .lastRow {
                        height: 700px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
                  
                   
                  @media screen and (max-width:600px) {
                  .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 20px; padding-right: 20px
                    }
                    .threeViewB {
                        padding-bottom : 40px
                    }
                     
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                    
                      .believecontent{
                          margin-top:70px;
                          white-space: nowrap;
                         
                      }
                      
                      .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                      }
                      .custom-p-tag {
                        font-size:1rem !important;
                    }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        // background-repeat: round !important;
                    }
                    .div_bg_img{
                        min-height: 230px
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    
                }
                
                @media screen and (min-width: 600px) and (max-width:780px) {
           
                    
                    .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
               `}
                </Style>
                <div className='version-2'>
                    <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
                        <div className={"home-sticky-crop home"} style={{ top: 0 }}>
                            <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                                <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                            </Link>
                            <Header page="ventures" />
                        </div>
                    </div>
                    <div style={{ marginTop: 100 }}></div>
                    <div className='py-5 container' style={{ fontSize: 22, lineHeight: "26px"}}>
                        We look for founder/market fit and collaborate from the ideation stage. <br/><br/>
                        Our focus is pre seed investment to build early stage companies in emerging markets. <br/><br/>
                        Our founders are leaders in their communities and experts in their problem spaces. <br/><br/>
                        We are obsessed with building community led solutions. <br/><br/>
                        We work with governments, public and private sector, academia and civil society organizations in emerging markets. <br/><br/>
                        We love New York City. Our New York City roots run deep. We acknowledge and appreciate that New York City is a microcosm of the world. <br/><br/>
                        Our carbon sensitive technology portfolio. <br/><br/>
                    </div>
                </div>

                <div className=''>
                        <img className='img-fluid' src={slide1img5} />
                    </div>

            </React.Fragment>
        );
    }
}

export default Ventures;