import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from '../images/Blacktransparent.png'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <div className="side-panel">
                        <div className="logo">
                            <img className="img-responsive" src={Logo} />
                        </div>
                    </div>
                    <div className="main-panel">
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);