import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoginData } from "../redux/actions";
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/Blacktransparent.png'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                email: "",
                password: "",
                login_type: "",
            }
        }
    }

    submit() {
        if (this.props.email != "" && this.props.password != "" && this.props.login_type != "") {
            if (this.props.login_type == "dataemergence") {
                let postJson = { loginId: this.props.email, password: this.props.password, projectName: this.props.login_type };
                var that = this;
                const request = new ZiFetchRequest();
                request.setDomain('manage');
                request.setEvent('getLoginIntern');
                request.setMessage(JSON.stringify(postJson));
                request.setDatetime(new Date().toString());
                this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                    if (response == null || response.getMessage() == 'Invalid Request') {
                        console.warn('Invalid Request. Please try again later');
                    } else {
                        let responseData = JSON.parse(response.getMessage());
                        var response = responseData.response
                        var responseCode = responseData.responseCode
                        var id = responseData.id
                        if(response == "Success") {
                            var url = "https://teqbahn.com/dataemergence-web/redirect/" + id
                            window.location.href = url
                        }
                    }
                });
            } else {
                alert("Are you valid user? Please contact Teqbahn admin")
            }
        } else {
            alert("Invalid Credentials!")
        }
    }

    render() {
        let { email, password } = this.props;
        let { errors } = this.state;
        return (
            <div className="admin-module">
                <div class="container vcenter">
                    <div class="card card-container">
                        <img id="profile-img" class="img-responsive" src={Logo} />
                        <form class="form-signin">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email"
                                    className={(typeof errors['email'] != "undefined" && errors['email'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="Email address" value={email} onChange={(e) => { this.props.setLoginData("email", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.email}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Password</label>
                                <input type="password" name="password" id="password"
                                    className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="***********" value={password} onChange={(e) => { this.props.setLoginData("password", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.password}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Type</label>
                                <select className="form-control" name="login_type" onChange={(e) => { this.props.setLoginData("login_type", e.target.value) }}>
                                    <option value="">Select Type</option>
                                    <option value="dataemergence">Internship program - DataEmergence</option>
                                    <option value="ip-tilli">Internship program - Tilli</option>
                                    <option value="ip-gis">Internship program - GIS</option>
                                    <option value="ip-novelcoronanews">Internship program - NovelCoronaNews</option>
                                    <option value="ip-umedme">Internship program - UmedMe</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </select>
                                <div className="custom-invalid-feedback">{errors.login_type}</div>
                            </div>
                            <button class="btn btn-lg btn-primary btn-block btn-signin" type="button" onClick={() => this.submit()}>Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.loginReducer.email,
        password: state.loginReducer.password,
        login_type: state.loginReducer.login_type,
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginData: (key, value) => dispatch(setLoginData(key, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);