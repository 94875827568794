import React, { useState, useEffect } from 'react';

const Text = () => {

    return <div class="scrolling-words-container-v4">
        <div class="scrolling-words-box-v4">
            <ul>
                <li>Real</li>
                <li>Scale</li>
                <li>Meaningful</li>
                <li>Real</li>
            </ul>
        </div>
        <span>Connectivity</span>
    </div>;
};


export default Text;
