import MyConstant from '../config/MyConstant';


export function pageNumbers(totalPages, page, maxLength) {
    if (maxLength < 5) throw "maxLength must be at least 5";
    function range(start, end) {
        return Array.from(Array(end - start + 1), (_, i) => i + start);
    }
    let pagination = [];
    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
        // no breaks in list
        pagination = range(1, totalPages);
    } else if (page <= maxLength - sideWidth - 1 - rightWidth) {
        // no break on left of page
        pagination = range(1, maxLength - sideWidth - 1)
            .concat(0, range(totalPages - sideWidth + 1, totalPages));
    } else if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        pagination = range(1, sideWidth)
            .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    } else {
        // Breaks on both sides
        pagination = range(1, sideWidth)
            .concat(0, range(page - leftWidth, page + rightWidth),
                0, range(totalPages - sideWidth + 1, totalPages));
    }

    return pagination;
}

export function loginStatusCheck() {
    var loginType = localStorage.getItem("loginUserType");
    if (!loginType) {
        window.location.href = MyConstant.keyList.projectUrl + '/userlogin'
    }

}
