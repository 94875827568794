import React from 'react';
import footer1 from '../images/speakup/footer1.png';
import footer2 from '../images/speakup/footer2.png';
import MyConstant from '../config/MyConstant';
import { Link } from "react-router-dom";

export default class Footer extends React.Component {

    menuClick() {
        window.scrollTo(0, 0);
    }

    render() {
        let year = new Date().getFullYear();

        return <div className='footer'>
            <div className='grey-bg py-5'>
                <div className="row ">
                    <div className="col-sm-12 text-center pb-3 share-text">
                        Share
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-12 text-center" id="social-icons">
                        <div className="mx-2 d-inline ">
                            <a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location}>
                                <i className="fa fa-facebook-f"></i>
                            </a>
                        </div>
                        <div className="mx-2 d-inline ">
                            <a target="_blank" href={"https://twitter.com/intent/tweet?text=Teqbahn&url=" + window.location}>
                                <i className="fa fa-twitter"></i>
                            </a>
                        </div>
                        <div className="mx-2 d-inline ">
                            <a target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url="+ window.location}>
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </div>
                        <div className="mx-2 d-inline ">
                            <a href={"mailto:?subject=Teqbahn&body=" + window.location} >
                                <i className="fa fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5">
                <div className="row mx-0 text-center">
                    <div className="col-sm-12">
                        <img className="img-fluid footer-2" src={footer2} />
                    </div>
                </div>
                <div className="row mx-0 text-center">
                    <div className="col-sm-12">
                        <img className="img-fluid footer-1" src={footer1} />
                    </div>
                </div>
                <div className="row mx-0 text-center py-2 f-text">
                    <div className="col-sm-12">
                        <p>SpeakUp supports the Sustainable Development Goals</p>
                        <p>SpeakUp Targets SDG #3 Good Health and Well Being</p>
                    </div>
                </div>
                <div className="row mx-0 text-center py-2 f-text-end">
                    <div className="col-sm-12">
                        <p>SpeakUp is a <a href="https://umed.me/" style={{ color: '#e74c33' }}>Umed.Me</a> Collaboration</p>
                        <p>Umed.Me is a <a href="https://teqbahn.com/" style={{ color: '#e74c33' }}>Teqbahn</a> Company</p>
                        <p>244 Fifth Avenue, Suite 2724, New York, N.Y. 10001</p>
                        <p>Email: Contact@teqbahn.com</p>
                        <br />
                        <p>&#169;{year} by Teqbahn | <Link onClick={() => this.menuClick()} to={MyConstant.keyList.projectUrl + "/privacy-policy"}>Privacy Policy</Link></p>
                    </div>
                </div>
            </div>
        </div>;
    }
}
