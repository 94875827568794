import React, { useEffect, useState } from 'react';
import Logo from '../images/version2/LabsWhite.png'
import Header from './component/Header';
import TeqitLogo from '../images/version2/TeqitLogo.png'
import downloadIos from '../images/version2/download-ios.png';
import downloadAndroid from '../images/version2/google-play-badge.png';
import Modal from '../components/Modal';

let videoList = {
    "Education": [],
    "Work": [
        { url: "https://tv.teqbahn.com/teqitTv-api/files?processType=video&fileName=6efd655d-97c0-4379-b422-c1bcf6938e39.mp4", title: "Group Messages" },
        { url: "https://tv.teqbahn.com/teqitTv-api/files?processType=video&fileName=10da9d9b-d2bd-4a60-bb83-d57999a94568.mp4", title: "Direct Messages" },
        { url: "https://tv.teqbahn.com/teqitTv-api/files?processType=video&fileName=36742aee-5fb4-4159-9ef9-c390c20deb52.mp4", title: "Docs" },
        { url: "https://tv.teqbahn.com/teqitTv-api/files?processType=video&fileName=b80646d7-2299-4b9e-81ee-e1f7d9825238.mp4", title: "Calendar - Add Meeting" },
        { url: "https://tv.teqbahn.com/teqitTv-api/files?processType=video&fileName=57c13c13-9d27-458f-925e-49d376cb3ff3.mp4", title: "Message in Mobile App" }
    ],
    "Community": [],
    "Health": [],
}
function Teqit(props) {
    let { bannerState } = props;
    let [orgType, setOrgType] = useState("");
    let [videoUrl, setVideoUrl] = useState("");
    let [videoTitle, setvideoTitle] = useState("");


    useEffect(() => {
        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("home-sticky-crop");
                } else {
                    header.classList.remove("home-sticky-crop");
                }
            }
        };
        drawCircle()
    }, [])


    function drawCircle() {
        let orgType = ["Community", "Health", "Work", "Education"]
        var div = 360 / orgType.length;
        var radius = 160;
        if (window.innerWidth <= 768) {
            radius = 130;
        }

        var parentdiv = document.getElementById('teqit-parent');
        var offsetToParentCenter = parseInt(parentdiv.offsetWidth / 2); //assumes parent is square
        var offsetToChildCenter = 50;
        var totalOffset = offsetToParentCenter - offsetToChildCenter;
        for (var i = 0; i < orgType.length; ++i) {
            var childdiv = document.createElement('div');
            childdiv.className = 'orgtype';
            childdiv.style.position = 'absolute';
            var y = Math.sin((div * (i + 1)) * (Math.PI / 180)) * radius;
            var x = Math.cos((div * (i + 1)) * (Math.PI / 180)) * radius;
            childdiv.style.top = (y + totalOffset).toString() + "px";
            childdiv.style.left = (x + totalOffset).toString() + "px";
            childdiv.textContent = orgType[i];
            childdiv.onclick = (function (i) {
                return function () {
                    viewOrg(orgType[i]);
                }
            })(i);

            parentdiv.appendChild(childdiv);
        }
    }

    function viewOrg(type) {
        setOrgType(type)
    }

    return <div className='version-2'>
        <div className='block1 teqit' style={{ marginTop: bannerState ? (window.screen.width < 768 ? 140 : 70) : 0 }}>
            <div className={bannerState ? 'menu-banner-closed' : ""} id="myHeader">
                <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                <Header page="home" />
            </div>
            <div className='bg' style={{ paddingTop: 100 }}>
                <div className='teqit-link'>
                    <div id="teqit-parent">
                        <img className="img-fluid" src={TeqitLogo} style={{ maxWidth: 100 }} />
                    </div>
                </div>
                <div className="d-flex justify-content-center my-5">
                    <div className='m-2'>
                        <a href="https://apps.apple.com/in/app/teqit/id6447229697">
                            <img className='img-fluid' src={downloadIos}></img>
                        </a>
                    </div>
                    <div className='m-2'>
                        <a href="https://play.google.com/store/apps/details?id=com.teqbahn.teqit">
                            <img className='img-fluid' src={downloadAndroid}></img>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        {
            orgType !== "" && <Modal
                visible={orgType !== ""}
                closeModal={() => {
                    setOrgType("")
                    setVideoUrl("")
                    setvideoTitle("")
                }}
                heading={videoTitle ? videoTitle : "Videos"}
                size={"modal-lg"}
                body={<React.Fragment>
                    {
                        videoUrl ? <div className='video-setup'>
                            <div className='back-video' onClick={() => {
                                setVideoUrl("")
                                setvideoTitle("")
                            }}>
                                <i className='fa fa-angle-left' />
                            </div>
                            <video controls width="100%">
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div> : <React.Fragment>
                            {
                                (videoList[orgType] && videoList[orgType].length > 0) ? <React.Fragment>
                                    <div className='row mx-0'>
                                        {
                                            videoList[orgType].map((item, index) => {
                                                let { title, url } = item;
                                                return <div className='col-sm-4 text-center' key={index}>
                                                    <div className='video-view' onClick={() => {
                                                        setVideoUrl(url)
                                                        setvideoTitle(title)
                                                    }}>
                                                        <i className="fa fa-file-video-o"></i>
                                                        <div className='title'>{title}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </React.Fragment> : <div className='text-center'>
                                    <h4>Coming Soon.</h4>
                                </div>
                            }
                        </React.Fragment>
                    }

                </React.Fragment>}
            />
        }
    </div>
}

export default Teqit