import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MyConstant from './config/MyConstant';
import {useSelector} from 'react-redux';
import Sidebar from './components/Sidebar';

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
        const auth = useSelector(state => state.authAdminReducer);
        const isLoggedIn = typeof auth !== "undefined" && typeof auth.isAdminLoggedIn  !== "undefined" && auth.isAdminLoggedIn ? auth.isAdminLoggedIn : false;
        return (<Route {...rest} render={props => {
            return (
                (isLoggedIn && isLoggedIn != 0)
                    ? <Sidebar><Component {...props} /></Sidebar>
                    : <Redirect to={{ pathname: MyConstant.keyList.projectUrl + "login", state: { from: props.location } }} />
            )}
        } />
    )
}


export default AdminPrivateRoute;