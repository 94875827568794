import React, { useState } from 'react';
import slide1icon from "../images/version3/slider1/icon.jpg";
import slide2icon from "../images/version3/slider2/icon.png";
import slide3icon from "../images/version3/slider3/icon.png";
import slide4icon from "../images/version3/slider4/icon.png";

import Modal from '../components/Modal';
import Slider from '../components/Slider';
import { Link } from 'react-router-dom';
import MyConstant from '../config/MyConstant';
import { getSlider } from './SliderFiles';

export default function LearnAboutUs(props) {
    let [active, setActive] = useState("");
    return <React.Fragment>
        <div className='learn-about-us d-flex justify-content-center'>
            <Link to={MyConstant.keyList.projectUrl+ "/slide/1"} className='learn-about-box' onClick={(e) => {
                e.preventDefault();
                setActive("1")
            }}>
                <img className='img-fluid' src={slide1icon} />
            </Link>
            <Link to={MyConstant.keyList.projectUrl+ "/slide/2"} className='learn-about-box' style={{ backgroundColor: "#284ba1" }} onClick={(e) => {
                e.preventDefault();
                setActive("2")
            }}>
                <img className='img-fluid' src={slide3icon} />
            </Link>
            <Link to={MyConstant.keyList.projectUrl+ "/slide/3"} className='learn-about-box' onClick={(e) => {
                e.preventDefault();
                setActive("3")
            }}>
                <img className='img-fluid' src={slide2icon} />
            </Link>
            <Link to={MyConstant.keyList.projectUrl+ "/slide/4"} className='learn-about-box' onClick={(e) => {
                e.preventDefault();
                setActive("4")
            }}>
                <img className='img-fluid' src={slide4icon} />
            </Link>
        </div>
        {
            active && <Modal
                visible={active ? true : false}
                closeModal={() => {
                    setActive("")
                }}
                heading={""}
                size={"modal-xl"}
                body={<React.Fragment>
                    <Slider slider={getSlider(active, "80vh")} />
                </React.Fragment>
                }
                footer={<button className='btn btn-sm btn-primary' style={{ cursor: "pointer" }} onClick={() => setActive("")}>Close</button>}
            />
        }
    </React.Fragment>;
}
