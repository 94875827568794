import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Caitlin from '../images/version3/team/Caitlin.png'
import Charlie from '../images/version3/team/Charlie.jpg'
import Eleanor from '../images/version3/team/Eleanor.jpg'
import Ravi from '../images/version3/team/Ravi.jpg'
import Vidya from '../images/version3/team/Vidya.jpg'
import ChristinaBradic from '../images/version3/team/ChristinaBradic.jpeg'
import MarkAnsay from '../images/version3/team/MarkAnsay.jpg'
import KathleenAnsay from '../images/version3/team/KathleenAnsay.jpg'
import RubenBarcenilla from '../images/version3/team/RubenBarcenilla.jpg'
import MariaGargano from '../images/version3/team/MariaGargano.jpg'
import Config from '../helper/Config';
import { Link } from "react-router-dom";

export default function OurTeam(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("OurTeam", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 150 }} className='main-container'>
            <div className='text-center'>
                <h1>
                    Our Team
                </h1>
            </div>

            <div className='container my-5'>
                <div class="team-content">
                    <img class="left-side" src={Vidya} alt="Vidya" />
                    <p className='title'>Vidya Sri, MBA: Chief Catalyst</p>
                    <p>Vidya Sri is Founder of Gangashakti, Co-Founder of Teqbahn Labs and Everywoman Treaty. Vidya works at the intersection of human rights and technology to develop evidence-based interventions for issues disproportionately impacting women and girls. As the founder of Gangashakti, Vidya developed a community-led innovation model to help conduct groundbreaking research to address gaps in marginalized communities. As a Human Rights Policy Fellow at the Harvard Kennedy School of Government, Vidya led the Initiative on violence against women (vaw) where she advanced and scaled her community-led innovation model through a first of its kind multi-year global consultation examining the legal and implementation gaps in the global framework on vaw. This resulted in 17 expert memos which laid the groundwork for a global treaty on vaw. Vidya established an early global coalition of 1,700 members from 128 nations, including 840 organizations and managed 8 regional working groups of 120+ experts from 70 countries. Prior to women’s rights work, Vidya was a vice president in the banking industry, where she led teams of 150 people, and managed assets exceeding $1 billion. Vidya’s story is shared in the book, “Social Justice and the Power of Compassion” by Marguerite Guzman Bouvard. </p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={Charlie} alt="Charlie" />
                    <p className='title'>Dr. Charlie Clements, MD, MPH: Human Rights Practice and Public Health Expert</p>
                    <p>Charlie Clements is a retired American physician and a human rights activist. He served as the executive director of the Carr Center for Human Rights Policy at the John F. Kennedy School of Government at Harvard University from 2010 to 2016. From 2003 to 2010, Dr. Clements served as president and CEO of the Unitarian Universalist Service Committee, and before that he was the president of Physicians for Human Rights. Dr. Clements wrote Witness to War, which was published in 1983 and became the subject of a 1985 Academy Award-winning short documentary of the same name. This book chronicles his experience as a distinguished graduate of the Air Force Academy who had flown more than 50 missions in the Vietnam War. Afterwards, Clements entered medical school and came to realize that exploitation, poverty, and injustice were often the origin of illness and injury. Later, as a newly trained physician, he heard the U.S. was sending military aid to the Salvadoran government, he chose to work in the midst of El Salvador's civil war in an area controlled by the Salvadoran guerrilla resistance group, FMLN. There, the villages he served were bombed, rocketed, or strafed by some of the same aircraft in which he had previously trained. Upon returning to the U.S., Clements testified in Congress and led congressional delegations to the region. At the conclusion of the civil war in 1992, he was invited as a special guest to the signing of the Peace Accords in Mexico City and in 2009 to the inauguration of the first FMLN president in El Salvador, Mauricio Funes. In 1997 Clements represented Physicians for Human Rights in the signing of the treaty to ban landmines and later at the Nobel Prize ceremony for the International Campaign to Ban Landmines.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={Eleanor} alt="Eleanor" />
                    <p className='title'>Dr.Eleanor Nwadinobi, MBBS, EMA, FAAC: Global Health and Gender Expert</p>
                    <p>Dr Nwadinobi is a medical doctor and international health, women peace and security, gender and human rights expert. She holds a European Union masters in Human rights and Democratisation (EMA) from Venice, Italy. As President of the Widows Development Organisation, Dr Nwadinobi joined in advocating for the law protecting widows in Enugu State, the Violence Against Persons Prohibition(VAPP) Act at Federal level and most recently Abia State VAPP act. She is the International President of the Medical Women's International Association (MWIA) and first Nigerian to assume this position. Dr Nwadinobi sits on the boards of several National and International Organisations. She has authored several publications and received numerous awards of merit. Her personal profile is featured in Friedrich Ebert Stiftung publication, “The hands that build Nigeria: Nigerian women role models”.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={Ravi} alt="Ravi" />
                    <p className='title'>Ravi Madhusudhan: Chief Technologist</p>
                    <p>Ravi is an aeronautical engineer and senior technology expert with over 2 decades of experience at the forefront of information technology. He has deep expertise as an information technology architect for the Fortune 1000, social impact and startup ecosystems. Ravi has deployed systems in both traditional cloud native settings and decentralized cloud settings across sectors including healthcare, telecommunications, insurance, biotech, rural digital connectivity and government. Ravi has pioneered information solutions to address the digital divide with communication tools that perform complex business functions using sms based messaging. He has been building carbon sensitive information technology for the past 10 years. Ravi works through collaboration to raise awareness on digital technology as a primary carbon producer. Ravi has worked on the domestic and international level to promote gender equity by investing in transformative research and innovation.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={Caitlin} alt="Caitlin" />
                    <p className='title'>Caitlin O'Quinn: Operations & Project Management</p>
                    <p>Caitlin is an educator and scholar interested in politics and power through an intersectional and gendered lens. She has spent 6 years in the violence against women & girls space, with an interest in law and primary prevention & education strategies. Her research interest is on income inequality, alternative & inclusive economic models, and collaborative governance. Caitlin has led large, remote teams working across time zones and cultures and is skilled in operationalizing inclusion.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={ChristinaBradic} alt="ChristinaBradic" />
                    <p className='title'>Christina Bradic, MPH: Global Health</p>
                    <p>Christina has extensive experience working at the intersection of global health and human rights, with a focus on forced displacement, the right to migrate, and the resulting impact on the human experience. Her passion is using data to influence policy and she has worked on five pieces of U.S. legislation, including the International Violence Against Women Act and the U.S. Trafficking Victims Protection Act, advocating for a provision for unaccompanied minors at the U.S. border. She has completed a data analysis of the effects of natural climate phenomena on refugee migration across the Mediterranean Sea, a comprehensive examination resulting in changes to policies about how humanitarian aid is distributed in the region. Christina's body of work is largely centered on humanitarian work with an emphasis on fragile contexts. She has created and implemented programs in refugee camps in Jordan, Iraq, and Kenya, building child safe spaces where children have psychosocial support and parents are provided economic opportunity. Additionally, she has served as a subject matter expert on statelessness for children of Haitian migrants living in the Dominican Republic and Kamlari, girls in indentured servitude in Nepal. With experience across more than 15 countries, her focus on gender, ensures that programs have a strong equity and justice component. Christina holds a Masters of Public Health from The George Washington University, A post-graduate Certificate in Global Mental Health from the Harvard Program on Refugee Trauma, credentials from the UN GenderPro Alliance, and is currently a JD candidate at Syracuse Law.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={MarkAnsay} alt="MarkAnsay" />
                    <p className='title'>Mark Ansay: Health Tech Incubation</p>
                    <p>Mark is a hemodialysis registered nurse. He has worked with several hospital systems and the two largest hemodialysis companies globally. He has long term experience in both outpatient (chronic) and hospital (acute) settings. Mark has expertise as a staff nurse, nurse in charge, and clinical coordinator. He has deepened his knowledge base in the hospital setting by becoming very skilled in operations, compliance, finance, and training/onboarding which he has applied extensively in a role serving a multi-hospital setting. His strong leadership and deep commitment to the patient experience have made him an exceptional medical professional.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={KathleenAnsay} alt="KathleenAnsay" />
                    <p className='title'>Kathleen Ansay: Research & Development</p>
                    <p>Kathleen Ansay is a nurse educator with extensive expertise in education across medical specialities, functional units, and departments at a level 2 trauma hospital. She has served as a mentor for early stage nursing students and mid-career nursing professionals. Kathleen has participated in the successful restructure of a nursing orientation program and has introduced gamification as an effective engagement tool. She helped pioneer the development of the first successful nurse residency program in her hospital system. This program was awarded the highest level of accreditation from the American Nursing Credential Center. Kathleen is an active member of the National Association of Orthopedic Nurses, American Nurses Association, and the Medical Surgical Nursing Board</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={RubenBarcenilla} alt="RubenBarcenilla" />
                    <p className='title'>Ruben Barcenilla Jr.: Creative Design</p>
                    <p>Ruben is a multi-disciplinary creative working as an art director at an ad agency in New York. He has been creating and designing interactive brand experiences for over a decade. Ruben refined his extensive skills by working for one of the world's largest luxury vehicle companies. He mastered his creative craft there, from photo editing to video composition, while also doing freelance work. Ruben is obsessed with creating and continuous improvement. He is deeply skilled in the brand experience which elevates his brand delivery across sectors.</p>
                </div>
                <div class="team-content mt-5">
                    <img class="left-side" src={MariaGargano} alt="MariaGargano" />
                    <p className='title'>Maria Gargano: Trauma Informed Interventions</p>
                    <p>Maria Gargano is a San Diego native. Her research focuses on migration, interpersonal violence (including Intimate Partner and Gender-Based Violence), and mental health. Her considerable field experience in several major transit countries have made her particularly interested in developing interventions that are trauma-informed, culturally meaningful, and feasible to apply in displacement and other low-resource contexts. Maria is a co-founder of Umed.Me Migration, a personal health care app developed by Teqbahn and co-created with the migrant community in Tijuana to help address gaps in the continuity of medical care that disproportionately impacts women and girls. Maria's research has been published in <a target='_blank' href='https://www.mdpi.com/1660-4601/19/6/3476'>Mental Health in the Transit Context: Evidence from 10 Countries</a>.</p>
                </div>
            </div>
        </div>
    </div>;
}
