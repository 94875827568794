import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import PrivacyPolicy from '../src/PrivacyPolicy';
import Connect from '../src/Connect'
import Footer from '../src/Footer';
import Config from '../src/helper/Config';
import LandingPage_3 from '../src/LandingPage_3'
import Footer_3 from '../src/Footer_3'
import SlideGIS from '../src/slides/SlideGIS'
import SlideTilli from '../src/slides/SlideTilli'
import SlideUmedME from '../src/slides/SlideUmedME'
import Register from "./containers/Register";
import Login from "./containers/Login";
import DemoLogin from "./containers/DemoLogin";
import AdminLogin from "./containers/AdminLogin";
import Dashboard from "./containers/Dashboard";
import MyConstant from './config/MyConstant';
import AdminPrivateRoute from './AdminPrivateRoute';
import JsonDiff from './tools/JsonDiff';

import AddUser from '../src/admin/AddUser'
import CreatePost from '../src/admin/CreatePost'
import ImageUpload from '../src/admin/ImageUpload'
import UserLogin from '../src/admin/UserLogin'
import Admin_Login from '../src/admin/Admin_Login'
import UserList from '../src/admin/UserList'
import UserPost from '../src/admin/UserPost'
import ViewPostDetails from '../src/admin/ViewPostDetails'
import AdminViewPostDetails from '../src/admin/AdminViewPostDetails'
import SpeakUp from './speakup/SpeakUp';
import SpeakUpStory from './speakup/SpeakUpStory';
import SpeakUpUnsubscribe from './speakup/SpeakUpUnsubscribe';
import TPR from './TPR';
import WelcomeBanner from './components/WelcomeBanner';
import FooterV2 from './version2/component/FooterV2';
import Mission from './version2/Mission';
import TeqedV2 from './version2/Foundation';
import BlogV2 from './version2/Blog';
import TPRHomeV2 from './version2/TPRHome';
import CSITV2 from './version2/CSIT';
import Teqit from './version2/Teqit';
import Platform from './version2/Platform';
import OurTeam from './version2/OurTeam';
import OnlineLibrary from './version2/OnlineLibrary';
import Slide from './version3/Slide';
import Ventures from './Ventures';
import LandingPage4 from './version2/LandingPage4';
import MidYearUpdate2024 from './version2/MidYearUpdate2024';



const { ZiFetchServiceClient } = require('./protobuf/generated/ZiFetchService_grpc_web_pb');
let grpcClient = new ZiFetchServiceClient(MyConstant.keyList.grpcURL, {}, {});

const umedmeProto = require('./protobuf/umedme/umedme_grpc_web_pb');
let umedmeGrpcClient = new umedmeProto.ZiFetchServiceClient(MyConstant.keyList.umedmeGrpcURL, {}, {});

const speakupProto = require('./protobuf/speakup/speakup_grpc_web_pb');
let speakupGrpcClient = new speakupProto.ZiFetchServiceClient(MyConstant.keyList.speakupGrpcURL, {}, {});


const history = createHistory();
const projectUrl = Config.projectUrl;

class RouterApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginModalView: false,
            bannerState: false
        }
        this.toggleLogin = this.toggleLogin.bind(this);
    }

    componentDidMount() {
        let bannerState = localStorage.getItem("banner-closed");
        if (bannerState) {
            this.setState({
                bannerState: false
            })
        }  
    }

    toggleLogin() {
        this.setState({
            loginModalView: !this.state.loginModalView
        })
    }

    bannerClose() {
        this.setState({
            bannerState: false
        })
    }


    render() {
        let { bannerState } = this.state
        return (
            <div>
                <Router>
                    <Route exact path={projectUrl + "/privacy-policy"} history={history} render={(props) =>
                        <div>
                            <PrivacyPolicy {...props} />
                            <Footer_3 page="privacy" />
                        </div>
                    } />
                    
                    <Route path={projectUrl + "/speakup/verify-subscription/:id"} history={history} render={(props) =>
                        <React.Fragment>
                            <SpeakUp {...props} />
                        </React.Fragment>
                    } />
                    <Route path={projectUrl + "/speakup/unsubscribe/:id/:email"} history={history} render={(props) =>
                        <React.Fragment>
                            <SpeakUpUnsubscribe {...props} grpcClient={speakupGrpcClient} />
                        </React.Fragment>
                    } />
                    
                    <Route exact path={projectUrl + "/share-story"} history={history} render={(props) =>
                        <React.Fragment>
                            <SpeakUpStory {...props} grpcClient={speakupGrpcClient} />
                        </React.Fragment>
                    } />
                    <Route exact path={projectUrl + "/slide-umedme"} history={history} render={(props) =>
                        <React.Fragment>
                            <SlideUmedME {...props} />
                        </React.Fragment>
                    } />
                    <Route exact path={projectUrl + "/slide-tilli"} history={history} render={(props) =>
                        <React.Fragment>
                            <SlideTilli {...props} />
                        </React.Fragment>
                    } />
                    <Route exact path={projectUrl + "/slide-gis"} history={history} render={(props) =>
                        <React.Fragment>
                            <SlideGIS {...props} />
                        </React.Fragment>
                    } />
                    <Route exact path={projectUrl + "/connect"} history={history} render={(props) =>
                        <div>
                            <div style={{ float: "right" }}>
                                <img src={require("./logo1.png")} alt="" style={{ width: 150, height: 150 }} />
                            </div>
                            <Connect {...props} />
                            <Footer />
                        </div>
                    } />

                    <Route exact path={projectUrl + "/register"} history={history} render={(props) =>
                        <Register {...props} />
                    } />
                    <Route exact path={projectUrl + "/login"} history={history} render={(props) =>
                        <Login {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/demo-login"} history={history} render={(props) =>
                        <DemoLogin {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/manage"} history={history} render={(props) =>
                        <AdminLogin {...props} grpcClient={grpcClient} />
                    } />

                    <Route exact path={projectUrl + "/tools/json-diff"} history={history} render={(props) =>
                        <JsonDiff {...props} grpcClient={grpcClient} />
                    } />

                    <AdminPrivateRoute exact path={projectUrl + "/dashboard"} history={history} component={(props) =>
                        <Dashboard {...props} grpcClient={grpcClient} />
                    } />

                    <Route exact path={projectUrl + "/adduser"} history={history} render={(props) =>
                        <AddUser {...props} grpcClient={grpcClient} />
                    } />

                    <Route exact path={projectUrl + "/createpost"} history={history} render={(props) =>
                        <CreatePost {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/imageupload"} render={(props) =>
                        <ImageUpload {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/userlogin"} render={(props) =>
                        <UserLogin {...props} grpcClient={grpcClient} />
                    } />

                    <Route exact path={projectUrl + "/adminlogin"} render={(props) =>
                        <Admin_Login {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/userlist"} render={(props) =>
                        <UserList {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/userpost/:id?"} render={(props) =>
                        <UserPost {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/viewdetails/:id"} render={(props) =>
                        <ViewPostDetails {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/feeddetails/:id"} render={(props) =>
                        <AdminViewPostDetails {...props} grpcClient={grpcClient} />
                    } />
                    <Route exact path={projectUrl + "/tpr"} history={history} render={(props) =>
                        <React.Fragment>
                            <TPR page="TPR" {...props} />
                            <Footer_3 page="TPR" />
                        </React.Fragment>
                    } />


                    
                    {/* Version 2 */}
                    <Route exact path={projectUrl + "/"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <LandingPage4 bannerState={bannerState} {...props} />
                            <FooterV2 page="home" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/mid-year-update-2024"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <MidYearUpdate2024 bannerState={bannerState} {...props} />
                            <FooterV2 page="home" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/social-impact"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <LandingPage_3 bannerState={bannerState} {...props} />
                            <FooterV2 page="home" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/ventures"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <Ventures bannerState={bannerState} {...props} />
                            <FooterV2 page="home" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/platform"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"platform"} />
                            <Platform bannerState={bannerState} {...props} />
                            <FooterV2 page="platform" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/our-team"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"platform"} />
                            <OurTeam bannerState={bannerState} {...props} />
                            <FooterV2 page="ourteam" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/online-library"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"platform"} />
                            <OnlineLibrary bannerState={bannerState} {...props} />
                            <FooterV2 page="onlinelibrary" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/teqit-download"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <Teqit bannerState={bannerState} {...props} />
                            <FooterV2 page="teqit-download" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/mission"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <Mission bannerState={bannerState} {...props} />
                            <FooterV2 page="mission" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/TeqEd"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <TeqedV2 bannerState={bannerState} page="TeqEd" {...props} />
                            <FooterV2 page="TeqEd" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/blog"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <BlogV2 bannerState={bannerState} {...props} />
                            <FooterV2 page="blog" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/tprhome"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <TPRHomeV2 bannerState={bannerState}  page="TPRHome" {...props} />
                            <FooterV2 page="TPRHome" />
                        </div>
                    } />
                    <Route exact path={projectUrl + "/csit"} history={history} render={(props) =>
                        <div className={`banner-v3 ${bannerState ? "" : "closed"}`}>
                            <WelcomeBanner bannerClose={() => this.bannerClose()} page={"home"} />
                            <CSITV2 bannerState={bannerState} page="csit" {...props} />
                            <FooterV2 page="csit" />
                        </div>
                    } />
                    <Route path={projectUrl + "/slide/:id"} history={history} render={(props) =>
                        <React.Fragment>
                            <Slide {...props} />
                        </React.Fragment>
                    } />
                </Router>
            </div>
        )
    }
}


export default RouterApp;
