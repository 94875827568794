import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Cloud from '../images/version2/Cloud.jpg'
import Node from '../images/version2/Node.png'
import AI from '../images/version2/AI.jpg'
import Services from '../images/version2/Services2.png'
import IoT from '../images/version2/IoT.jpg'


export default function Platform(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("Platform", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 100 }} className='main-container'>
            <div className='main-header'>
                <h1>
                    Teq Platform
                </h1>
            </div>
            <div className='container-fluid pb-5'>
                <div className='parent-box'>
                    <div className='child-box d-flex column-reverse-mobile'>
                        <div className='image'>
                            <div className='small'>
                                <img className='img-fluid' src={Cloud} />
                            </div>
                        </div>
                        <div className='content'>
                            Teq is a MicroCloud seed platform that can be deployed wherever there is compute available from bare metal to private and public cloud.
                        </div>
                    </div>

                    <div className='child-box d-flex'>
                        <div className='content'>
                            Teq is a composition of application, infrastructure and storage services that collectively are needed for any online experience.
                        </div>
                        <div className='image' style={{ border: "1px solid #ddd" }}>
                            <img className='img-fluid' src={Services} />
                        </div>
                    </div>
                    <div className='child-box d-flex column-reverse-mobile'>
                        <div className='image'>
                            <img className='img-fluid' src={Node} />
                        </div>
                        <div className='content'>
                            Teq is based on container orchestrators and actor model compute. <br /><br />
                            This provides levels of compute orchestration and elasticity as the MicroCloud seed grows into a MacroCloud with User growth.
                        </div>
                    </div>

                    <div className='child-box d-flex'>
                        <div className='content'>
                            Teq is a live working simulation of stateful User experience (actors) and their interactions with application service actors. Users could also be IoT digital twins.
                        </div>
                        <div className='image'>
                            <div className='small'>
                                <img className='img-fluid' src={IoT} />
                            </div>
                        </div>
                    </div>

                    <div className='child-box d-flex column-reverse-mobile'>
                        <div className='image'>
                            <div className='small'>
                                <img className='img-fluid' src={AI} />
                            </div>
                        </div>
                        <div className='content'>
                            AI is embedded from the ground up and used by actors within Teq.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
