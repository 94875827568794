import React from 'react';
import { Slide } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css'
import '../slides.css'
import Jpg1 from './umedmenew/1.jpg'
import Jpg2 from './umedmenew/2.jpg'
import Jpg3 from './umedmenew/3.jpg'
import Jpg4 from './umedmenew/4.jpg'
import Jpg5 from './umedmenew/5.jpg'
import Jpg6 from './umedmenew/6.jpg'
import Jpg7 from './umedmenew/7.jpg'
import Jpg8 from './umedmenew/8.jpg'
import Jpg9 from './umedmenew/9.jpg'
import Jpg10 from './umedmenew/10.jpg'
import Jpg11 from './umedmenew/11.jpg'
import Jpg12 from './umedmenew/12.jpg'
import Jpg13 from './umedmenew/13.jpg'

class SlideUmedME extends React.Component {

    render() {

        return (
            <div>
                <div style={{padding: 20}}>
                    <a href={'https://teqbahn.com/'} style={{color: 'blue'}}>Home</a>
                </div>

                <Slide easing="ease" autoplay={false} >
                    <div className="each-slide">
                        <img src={Jpg1} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg2} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg3} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg4} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg5} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg6} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg7} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg8} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg9} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg10} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg11} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg12} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className="each-slide">
                        <img src={Jpg13} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>

                    {/*<div className="each-slide">
                        <a href="https://teqbahn.com/umedme/" target={'_blank'}>
                            <img src={Jpg11} alt="" style={{width: '100%', height: '100%', paddingLeft: 50, paddingRight: 50}}/>
                        </a>
                    </div>*/}
                </Slide>
            </div>
        );
    }
}

export default SlideUmedME
