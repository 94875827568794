module.exports = {
  keyList: {
    projectName: 'Teqbahn',
    projectUrl: '',
    // apiURL:'http://192.168.1.4:8091/dataemergence-api/',
    // grpcURL: 'http://192.168.1.4:8000',
    // apiURL: 'http://192.168.43.110:8091/teqbahn-api/',
    // grpcURL: 'http://192.168.43.110:8000',
    // analyticsApiURL: 'http://192.168.1.108:8091/teqbahn-analytics-api/',
    // analyticsGrpcURL: 'http://192.168.1.108:8000',
    apiURL: 'https://api.teqbahn.com/teqbahn-api/',
    grpcURL: 'https://api.teqbahn.com',
    umedmeApiURL: 'https://api.teqbahn.com/umedme-api/',
    umedmeGrpcURL: 'https://api.teqbahn.com',
    speakupApiURL: "https://api.teqbahn.com/speakup-api/",
    speakupGrpcURL: "https://api.teqbahn.com",
    analyticsApiURL: 'https://api.teqbahn.com/teqbahn-analytics-api/',
    analyticsGrpcURL: 'https://api.teqbahn.com',
    userLimit: 100,
    ipURL: 'https://apis.teqbahn.com/teqe-api/',
  }
}