import React from 'react';
import Config from "./helper/Config";
import { Link } from 'react-router-dom';
import Color_tiles from './images/Color_tiles.jpg';

import { Style } from "react-style-tag";



class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
      let year = new Date().getFullYear();
        return (
            <React.Fragment>


                <Style>
                    {`

.p-tag-font{
    font-family:Helvetica-Regular;
    font-size:30px; 
    text-align: center;
    margin-top: 10px;
    color:#FFF;
  }
  .span-tag{
    font-family:Helvetica-Regular;
    font-size:20px; 
    text-align: center;
    margin-top: 10px;
    color:#FFF; 
  }
  .footerTextcontent
  {
    margin-top: 100px;  
    text-align: center;
    line-height: 1.79em;


  }
  @media screen and (max-width:600px) {

    .p-tag-font{
        font-size:20px !important; 
        color:#FFF;
     }
     span-tag{
        font-size:18px !important; 
        color:#FFF;
     }
     .footerTextcontent
        {
            margin-top: 50px;  
        }

  }


  @media screen and (max-width:900px) {

    .footerTextcontent{
        margin-top: 60px;  
    }
  }

  .p-tag-font{
    font-family:Helvetica-Regular;
    font-size:16px; 
    text-align: center;
    color:white



  }
.p-tag-font1{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white



  }
  .span-tag{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white
  }
  .footerTextcontent
  {
    margin-top: 5px;  
    text-align: center;
    line-height: 1.79em;
  }
  .blackbar {
        min-height: 130px
  }
  @media screen and (max-width:600px) {
    .blackbar {
      min-height: 135px
      }
    .p-tag-font{
        font-family:Helvetica-Regular;
    font-size:16px; 
    text-align: center;
    color:white
     }
     .p-tag-font1{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white



  }
     .span-tag
      {
        font-size:18px !important; 
     }
     .footerTextcontent
        {
            margin-top: 5px;  
        }

  }


  @media screen and (min-width:600px && max-width:900px) {
      .blackbar {
        min-height: 135px
      }
    .footerTextcontent{
        margin-top: 5px;  
    }
  }

                    `}

                </Style>

                <div className="row">
                    <div className="col-12" style={{ backgroundColor: '#000', position: 'absolute', backgroundRepeat: "no-repeat", width: '100%', height: 200, backgroundSize: 'cover', marginLeft: 15 }} >

                        {/* <div className="panel-body" style={{ backgroundColor: "#fff", textAlign: "center",}}> */}

                        {/* <p className={"footerTextcontent"}  >
                    <p></p>
                    <p className="p-tag-font" >
                        New York: 244 Fifth Avenue, Suite 2724, New York, N.Y. 10001
                    </p>
                    <p className="p-tag-font"  >
                        New Jersey: 3135 Kennedy Blvd. Suite 181, North Bergen, NJ 07047
                    </p>
                    <p  className="p-tag-font" >
                        Email: Contact@teqbahn.com
                    </p>
                    <p style={{marginTop:10}}></p>
                    <span className="span-tag" >   ©2020 by Teqbahn. </span>
                    <Link  className="span-tag"  to={Config.projectUrl + "/privacy-policy"} >&nbsp;Privacy&nbsp;Policy</Link>&nbsp;&nbsp;
                </p> */}
                        <p className={"footerTextcontent"}  >
                            <p className="p-tag-font p-tag-font1" >Shared Values for Social Good</p>
                            <p className="p-tag-font p-tag-font1" >Get in touch and learn more</p>
                            <p className="p-tag-font">
                                244 Fifth Avenue, Suite 2724, New York, N.Y. 10001
                             </p>
                            <p className="p-tag-font" >
                                Email: Contact@teqbahn.com
                            </p>
                            <span className="p-tag-font" style={{}} >   ©{year} by Teqbahn. </span>
                            <Link className="p-tag-font" to={Config.projectUrl + "/privacy-policy"} >&nbsp;Privacy&nbsp;Policy</Link>&nbsp;&nbsp;
                        </p>
                        {/* </div> */}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Footer;
