import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import Slide_2 from '../images/TPR_Slide/TPR.Slidedeck_2.jpg';
import Slide_5 from '../images/TPR_Slide/TPR.Slidedeck_5.jpg';
import API from '../images/version2/API.png'
import Database from '../images/version2/database.png'
import RPCMECHANISMS from '../images/version2/RPCMECHANISMS.png'
import Config from '../helper/Config';
import { Link } from "react-router-dom";

export default function Platform(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("TRP Home", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 100 }} className='main-container'>
            <div className='main-header'>
                <h1>
                    Technology Process Reengineering (TPR)
                </h1>
            </div>
            <div className='container-fluid pb-5'>
                <div className='parent-box'>
                    <div className='child-box d-flex'>
                        <div className='content'>
                            <div>
                                TPR 
                            </div>
                            <div>
                                <ul>
                                    <li>Removes boundary layer friction, reducing cost per user experience.</li>
                                    <li>Real time continual learning systems based on accumulations and aggregations.</li>
                                    <li>Produce low resource information technology by reducing IT system entropy.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='image'>
                            <img className='img-fluid' src={Slide_2} />
                        </div>
                    </div>
                    <div className='child-box d-flex column-reverse-mobile'>
                        <div className='image'>
                            <img className='img-fluid' src={Slide_5} />
                        </div>
                        <div className='content'>
                            <div>
                                Part of the problem
                            </div>
                            <div>
                                <ul>
                                    <li>The centralization of technology is based on client server design.</li>
                                    <li>This centralized paradigm can become problematic when overlaid on the decentralized internet.</li>
                                    <li>This causes impedance mismatch with excessive layers of friction in the system.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='child-box d-flex'>
                        <div className='content'>
                            <div>
                            Resource Hogs - two of them
                            </div>
                            <div>
                                <h3>1. Internal API/RPC CALLS</h3>
                            </div>
                            <div>
                                <ul>
                                    <li>Adds layers of friction at varying systemic levels.</li>
                                    <li>Creates extra onward pressure on horizontal dataflows through gateways at the systems level.</li>
                                    <li>Known to increase cognitive and process load at the organizational level.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='image'>
                            <img className='img-fluid' src={API} />
                        </div>
                    </div>
                    <div className='child-box d-flex column-reverse-mobile'>
                        <div className='image'>
                            <div className='small'>
                                <img className='img-fluid' src={Database} />
                            </div>
                        </div>
                        <div className='content'>
                            <div>
                                <h3>2. RELATIONAL DATABASES & END USER EXPERIENCE</h3>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                    Incoming dataflows that engage a relational database through objects/functions
                                        <ul>
                                            <li>Ensure unnecessary and compounding friction.</li>
                                            <li>Increasing entropy while slowing down the system.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='child-box d-flex '>
                        <div className='content'>
                            <div>
                            A Solution -
                            </div>
                            <div>
                                <h3>Remove Internal API/RPC MECHANISMS</h3>
                            </div>
                            <div>
                                <ul>
                                    <li>Message passing mechanisms for frictionless dataflows based on the actor/agent model.</li>
                                    <li>
                                        Message Types are the design progenitor of the system and inform flow patterns.
                                        <ul>
                                            <li>Message types are a critical backbone for defining categories and emergent flow hierarchies.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        A User works with a Messenger that has a Message, the system is just the stateful interaction patterns of Users and Messengers. 
                                        <ul>
                                            <li>User type examples: operator, customer, advocate, employee, department, IoT device ...</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='image'>
                            <img className='img-fluid' src={RPCMECHANISMS} />
                        </div>
                    </div>

                    <div className='child-box d-flex'>
                        <div className='content' style={{alignItems: "center"}}>
                            <div>
                                USERS AND MESSAGES
                            </div>
                            <div>
                                <h3>Core Concepts</h3>
                            </div>
                            <div>
                                <ul>
                                    <li>Users and Messengers are the replacement core of the system.</li>
                                    <li>The Messengers and their Messages as they flow form the language of the system - in a way an interface to the system.</li>
                                    <li>TPR in its element simulates the user experience as an elastic flow of Messages.</li>
                                    <li>User experience as growing Data Trees.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
