import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './RouterApp';
import * as serviceWorker from './serviceWorker';

// For Redux:
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from './redux/store'

ReactDOM.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </PersistGate>
 </Provider>, document.getElementById('root'));

serviceWorker.unregister();
