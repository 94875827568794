import React from 'react';
import slide1img1 from "../images/version3/slider1/1.jpg";
import slide1img2 from "../images/version3/slider1/2.jpg";
import slide1img3 from "../images/version3/slider1/3.jpg";
import slide1img4 from "../images/version3/slider1/4.jpg";
import slide1img5 from "../images/version3/slider1/5.jpg";
import slide1img6 from "../images/version3/slider1/6.jpg";
import slide1img7 from "../images/version3/slider1/7.jpg";
import slide1img8 from "../images/version3/slider1/8.jpg";
import slide1img9 from "../images/version3/slider1/9.jpg";
import slide1img10 from "../images/version3/slider1/10.jpg";
import slide1img11 from "../images/version3/slider1/11.jpg";
import slide1img12 from "../images/version3/slider1/12.jpg";
import slide2img1 from "../images/version3/slider2/1.jpg";
import slide2img2 from "../images/version3/slider2/2.jpg";
import slide2img3 from "../images/version3/slider2/3.jpg";
import slide2img4 from "../images/version3/slider2/4.jpg";
import slide2img5 from "../images/version3/slider2/5.jpg";
import slide2img6 from "../images/version3/slider2/6.jpg";
import slide2img7 from "../images/version3/slider2/7.jpg";
import slide2img8 from "../images/version3/slider2/8.jpg";
import slide2img9 from "../images/version3/slider2/9.jpg";
import slide2img10 from "../images/version3/slider2/10.jpg";
import slide2img11 from "../images/version3/slider2/11.jpg";
import slide2img12 from "../images/version3/slider2/12.jpg";
import slide2img13 from "../images/version3/slider2/13.jpg";
import slide2img14 from "../images/version3/slider2/14.jpg";
import slide2img15 from "../images/version3/slider2/15.jpg";
import slide2img16 from "../images/version3/slider2/16.jpg";
import slide2img17 from "../images/version3/slider2/17.jpg";
import slide2img18 from "../images/version3/slider2/18.jpg";
import slide2img19 from "../images/version3/slider2/19.jpg";
import slide2img20 from "../images/version3/slider2/20.jpg";
import slide2img21 from "../images/version3/slider2/21.jpg";
import slide2img22 from "../images/version3/slider2/22.jpg";
import slide2img23 from "../images/version3/slider2/23.jpg";
import slide2img24 from "../images/version3/slider2/24.jpg";
import slide2img25 from "../images/version3/slider2/25.jpg";
import slide3img1 from "../images/version3/slider3/1.jpg";
import slide3img2 from "../images/version3/slider3/2.jpg";
import slide3img3 from "../images/version3/slider3/3.jpg";
import slide3img4 from "../images/version3/slider3/4.jpg";
import slide3img5 from "../images/version3/slider3/5.jpg";
import slide3img6 from "../images/version3/slider3/6.jpg";
import slide3img7 from "../images/version3/slider3/7.jpg";
import slide3img8 from "../images/version3/slider3/8.jpg";
import slide3img9 from "../images/version3/slider3/9.jpg";
import slide3img10 from "../images/version3/slider3/10.jpg";
import slide3img11 from "../images/version3/slider3/11.jpg";
import slide3img12 from "../images/version3/slider3/12.jpg";
import slide3img13 from "../images/version3/slider3/13.jpg";
import slide3img14 from "../images/version3/slider3/14.jpg";
import slide3img15 from "../images/version3/slider3/15.jpg";
import slide3img16 from "../images/version3/slider3/16.jpg";
import slide4img1 from "../images/version3/slider4/1.jpg";
import slide4img2 from "../images/version3/slider4/2.jpg";
import slide4img3 from "../images/version3/slider4/3.jpg";
import slide4img4 from "../images/version3/slider4/4.jpg";
import slide4img5 from "../images/version3/slider4/5.jpg";
import slide4img6 from "../images/version3/slider4/6.jpg";
import slide4img7 from "../images/version3/slider4/7.jpg";
import slide4img8 from "../images/version3/slider4/8.jpg";
import slide4img9 from "../images/version3/slider4/9.jpg";
import slide4img10 from "../images/version3/slider4/10.jpg";
import slide4img11 from "../images/version3/slider4/11.jpg";
import slide4img12 from "../images/version3/slider4/12.jpg";
import slide4img13 from "../images/version3/slider4/13.jpg";
import slide4img14 from "../images/version3/slider4/14.jpg";
import slide4img15 from "../images/version3/slider4/15.jpg";
import slide4img16 from "../images/version3/slider4/16.jpg";
import slide4img17 from "../images/version3/slider4/17.jpg";
import slide4img18 from "../images/version3/slider4/18.jpg";
import slide4img19 from "../images/version3/slider4/19.jpg";
import slide4img20 from "../images/version3/slider4/20.jpg";
import slide4img21 from "../images/version3/slider4/21.jpg";
import slide4img22 from "../images/version3/slider4/22.jpg";
import slide4img23 from "../images/version3/slider4/23.jpg";
import slide4img24 from "../images/version3/slider4/24.jpg";
import slide4img25 from "../images/version3/slider4/25.jpg";
import slide4img26 from "../images/version3/slider4/26.jpg";
import slide4img27 from "../images/version3/slider4/27.jpg";
import slide4img28 from "../images/version3/slider4/28.jpg";
import slide4img29 from "../images/version3/slider4/29.jpg";

export function getSlider(id, maxHeight) {
    let slider = [];
    switch (id) {
        case "1":
            slider = [
                <img src={slide1img1} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img2} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img3} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img4} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img5} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img6} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img7} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img8} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img9} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img10} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img11} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide1img12} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
            ];
            break;
        case "2":
            slider = [
                <img src={slide3img1} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img2} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img3} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img4} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img5} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img6} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img7} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img8} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img9} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img10} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img11} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img12} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img13} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img14} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img15} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide3img16} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
            ]
            break;
        case "3":
            slider = [
                <img src={slide2img1} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img2} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img3} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img4} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img5} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img6} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img7} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img8} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img9} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img10} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img11} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img12} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img13} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img14} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img15} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img16} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img17} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img18} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img19} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img20} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img21} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img22} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img23} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img24} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide2img25} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
            ];
            break;
        case "4":
            slider = [
                <img src={slide4img1} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img2} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img3} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img4} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img5} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img6} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img7} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img8} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img9} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img10} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img11} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img12} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img13} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img14} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img15} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img16} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img17} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img18} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img19} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img20} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img21} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img22} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img23} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img24} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img25} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img26} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img27} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img28} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
                <img src={slide4img29} className='carosal_img' style={{ maxHeight: maxHeight ? maxHeight : "100vh"}} />,
            ]
            break;
    }
    return slider;
}