import React from 'react';
import './adminStyles.css';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import MyConstant from '../config/MyConstant';
import AdminTopMenu from "./AdminTopMenu"
import Logo from '../images/Blacktransparent.png'
import Header from '../Header';


export default class ViewPostDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postedData: []
        }
    }


    componentDidMount() {
        document.body.style.background = "#DCDCDC";

        let { match } = this.props
        let { params } = match
        if (params) {
            this.getPostList(params.id)
        }
    }



    async getPostList(feedId) {
        //feedId: String
        let postJson = { feedId: feedId };
        console.log("**", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('manage');
        request.setEvent('getFeedDetails');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                console.log("responseData", responseData)
                let parseData = JSON.parse(responseData)
                console.log("responseData", parseData)
                this.setState({ postedData: [parseData.result] })

            }
        });

    }


    render() {

        let { postedData } = this.state

        var feedPostArray = []
        postedData.map((ival, i) => {
            let feedPostData = ival.feedData
            let imgPath = ""
            let imageShow = false
            if (feedPostData.coverImage) {
                imgPath = MyConstant.keyList.apiURL + "vp?action=coverimage&key=" + feedPostData.coverImage + "&id=" + "image"
                imageShow = true
            }

            let userPhoto = ""

            if (ival.userPhoto) {
                userPhoto = MyConstant.keyList.apiURL + "vp?action=coverimage&key=" + ival.userPhoto + "&id=" + "image"
            }

            let descriptionContent = feedPostData.description
            // console.log(feedPostData.description.length, "----", descriptionContent.length)
            feedPostArray.push(<>
                <div className='card'>
                    <div className='row' style={{ paddingBottom: 10 }}>
                        <div className='col-sm-2 col-xs-3'>
                            <img src={userPhoto} className="pro-usr-img" />
                        </div>
                        <div className='col-sm-10 col-xs-9' style={{ paddingLeft: 10,float:"none" }}>
                            <p className='pro-usr-name'> {ival.userName} </p>
                        </div>
                    </div>

                    <div className='row' style={{ padding: "10px 0px", borderTop: "1px solid #DDD" }}>
                        <div className='col-sm-1' />
                        <div className='col-sm-10'>
                            <p className='pro-usr-title'> {feedPostData.title} </p>
                        </div>
                        <div className='col-sm-1' />
                    </div>

                    {imageShow && <>
                        <div className='row' style={{ marginTop: 5, paddingTop: 5 }} >
                            <div className='col-sm-1' />
                            <div className='col-sm-10'>
                                <img src={imgPath} className="usr-post-photo" />
                            </div>
                            <div className='col-sm-1' />
                        </div>
                    </>}

                    <div className='row' style={{ paddingTop: 5, }}>
                        <div className='col-sm-1' />
                        <div className='col-sm-10'>
                            <div className='usr-html-content' dangerouslySetInnerHTML={{ __html: descriptionContent }} />

                        </div>
                        <div className='col-sm-1' />
                    </div>
                </div>
            </>)
        })

        return (<>

            <div className='adminPage-post '>
                {/* <AdminTopMenu activeTab={"viewpost"} /> */}
                <div className="header-with-menu" >
                    <div className="logo padding about-sticky-crop" id="myHeader">
                        <img src={Logo} />
                    </div>
                    <Header />
                </div>


                <div class="container">
                    <div className='row'>
                        <div className='col-sm-2' />
                        <div className='col-sm-8'>

                            {feedPostArray}

                        </div>
                        <div className='col-sm-2' />
                    </div>
                </div>
            </div>
        </>)
    }

}