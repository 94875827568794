import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoginData } from "../redux/actions";
import Logo from '../images/BlackTransparentNew.png'
import Config from '../helper/Config';

class DemoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                email: "",
                password: "",
                login_type: "",
            }
        }
    }

    submit() {
        if (this.props.email === "demo@teqbahn.com" && this.props.password === "0ro9G0ZB") {
            localStorage.setItem("demoValidation", "verified")
            window.location = Config.projectUrl
        } else {
            alert("Invalid Credentials!")
        }
    }

    render() {
        let { email, password } = this.props;
        let { errors } = this.state;
        return (
            <div className="admin-module">
                <div class="container vcenter">
                    <div class="card card-container">
                        <img id="profile-img" class="img-responsive" src={Logo} />
                        <form class="form-signin">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" id="email"
                                    className={(typeof errors['email'] != "undefined" && errors['email'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="Email address" value={email} onChange={(e) => { this.props.setLoginData("email", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.email}</div>
                            </div>
                            <div className="form-group mb-4">
                                <label>Password</label>
                                <input type="password" name="password" id="password"
                                    className={(typeof errors['password'] != "undefined" && errors['password'] != "") ? "custom-invalid  form-control" : " form-control"}
                                    placeholder="***********" value={password} onChange={(e) => { this.props.setLoginData("password", e.target.value) }} />
                                <div className="custom-invalid-feedback">{errors.password}</div>
                            </div>
                            <button class="btn btn-lg btn-primary btn-block btn-signin" type="button" onClick={() => this.submit()}>Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.loginReducer.email,
        password: state.loginReducer.password,
        login_type: state.loginReducer.login_type,
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setLoginData: (key, value) => dispatch(setLoginData(key, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoLogin);