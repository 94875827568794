import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Config from "../../helper/Config";
import LightBg from '../../images/midyearupdate/LightBg2.gif'

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    menuClick() {
        document.getElementById("navbar-mobile-icon").click();
        window.scrollTo(0, 0);
    }

    setIsShown(e, action) {
        if (window.innerWidth > 768) {
            if (action === "open") {
                e.currentTarget.classList.add("open")
            } else {
                e.currentTarget.classList.remove("open")
            }
        }
    }

    getImg() {
        return LightBg;
    }
    render() {
        let { page, style } = this.props;
        var menuTextColorClass = "nav-link"
        if (page === "TPRHome") {
            menuTextColorClass = "nav-link whiteLable"
        }
        return (
            <React.Fragment>
                {
                    page === "social-impact" && <div className='mid-year-icon'>
                        <Link to={Config.projectUrl + "/mid-year-update-2024"} onClick={() => this.menuClick()}>
                            <img src={this.getImg()} className='img-fluid' />
                        </Link>
                    </div>
                }

                <nav id="menu" style={style} className="menu-fixed">
                    <button className="navbar-toggle" id={"navbar-mobile-icon"} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className={`nav navbar-nav navbar-right + ${page === "TPRHome" ? "tprheader" : ""}`}>
                            {
                                page === "social-impact" && <li className='mid-year-icon-mobile text-center'>
                                    <Link to={Config.projectUrl + "/mid-year-update-2024"} onClick={() => this.menuClick()}>
                                        <img src={this.getImg()} className='img-fluid' />
                                    </Link>
                                </li>
                            }
                            <li className={page === "home" ? "nav-item px-2 active" : "nav-item px-2"} >
                                <Link to={Config.projectUrl + "/"} className={menuTextColorClass} onClick={() => this.menuClick()}>Home</Link>
                            </li>

                            <li className="dropdown"
                                onMouseEnter={(e) => this.setIsShown(e, "open")}
                                onMouseLeave={(e) => this.setIsShown(e, "close")}
                            >
                                <a href="#" className={`dropdown-toggle nav-link ${menuTextColorClass}`} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">About Us <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to={Config.projectUrl + "/our-team"} onClick={() => this.menuClick()}>Our Team</Link>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/online-library"} onClick={() => this.menuClick()}>Online Library</Link>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/TeqEd"} onClick={() => this.menuClick()}>TeqEd</Link>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/blog"} onClick={() => this.menuClick()}>Blog</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown"
                                onMouseEnter={(e) => this.setIsShown(e, "open")}
                                onMouseLeave={(e) => this.setIsShown(e, "close")}
                            >
                                <a href="#" className={`dropdown-toggle nav-link ${menuTextColorClass}`} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">LOB <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <span className='dropdown-head'>Lines Of Business</span>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/ventures"} onClick={() => this.menuClick()}>Teqbahn Ventures</Link>
                                    </li>
                                    <li>
                                        <a href={"https://teqit.me/"} onClick={() => this.menuClick()}>Teqit</a>
                                    </li>
                                    <li>
                                        <a href={"https://umed.me/"} onClick={() => this.menuClick()}>Umed.Me</a>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/social-impact"} onClick={() => this.menuClick()}>Social Impact</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown"
                                onMouseEnter={(e) => this.setIsShown(e, "open")}
                                onMouseLeave={(e) => this.setIsShown(e, "close")}
                            >
                                <a href="#" className={`dropdown-toggle nav-link ${menuTextColorClass}`} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Teq <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to={Config.projectUrl + "/tprhome"} onClick={() => this.menuClick()}>TPR</Link>
                                    </li>
                                    <li>
                                        <Link to={Config.projectUrl + "/csit"} onClick={() => this.menuClick()}>CSIT</Link>
                                    </li>
                                    {/* <li>
                                        <Link to={Config.projectUrl + "/platform"} onClick={() => this.menuClick()}>PLATFORM</Link>
                                    </li> */}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}