export const SET_LOGIN = 'SET_LOGIN';
export const RESET_LOGIN = 'RESET_LOGIN';

export const SET_ADMIN_LOGIN = 'SET_ADMIN_LOGIN';
export const RESET_ADMIN_LOGIN = 'RESET_ADMIN_LOGIN';


export const SET_ADMIN_AUTH = 'SET_ADMIN_AUTH';
export const RESET_ADMIN_AUTH = 'RESET_ADMIN_AUTH';

export const SET_SIGNUP = 'SET_SIGNUP';
export const RESET_SIGNUP = 'RESET_SIGNUP';