import React, { useEffect } from 'react';
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Logo from '../images/version2/LabsWhite.png'
import project_1 from '../images/project/project_1.png';
import project_2 from '../images/project/project_2.png';
import project_3 from '../images/project/project_3.png';
import project_4 from '../images/project/project_4.png';
import project_5 from '../images/project/project_5.png';
import project_6 from '../images/project/project_6.png';
import project_7 from '../images/project/project_7.png';
import { Link } from "react-router-dom";
import Config from '../helper/Config';


export default function OnlineLibrary(props) {
    let { bannerState } = props;

    useEffect(() => {
        analyticsUserTrack("OnlineLibrary", "Landing");
    }, [])

    return <div className='version-2'>
        <div className='block1' style={{ marginTop: (bannerState && window.screen.width < 768) ? 100 : 0 }}>
            <div className={"home-sticky-crop"} style={{ top: 0 }}>
                <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                    <img className='logo' src={Logo} style={{ position: "fixed", paddingTop: bannerState ? (window.screen.width < 768 ? 160 : 80) : 30 }} />
                </Link>
                <Header page="home" />
            </div>
        </div>
        <div style={{ marginTop: 150 }} className='main-container'>
            <div className='text-center mb-5'>
                <h1>
                    Online Library
                </h1>
            </div>
            <div style={{ minHeight: "50vh"}}>
                <div className='online-library-container d-flex justify-content-center'>
                    <div className='online-library-box'>
                        <a href="https://www.greenit.fr/wp-content/uploads/2019/11/GREENIT_EENM_etude_EN_accessible.pdf" target="_blank" title="The Environmental Footprint of the Digital World">
                            <img src={project_4} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://wedocs.unep.org/bitstream/handle/20.500.11822/37439/FB027.pdf" target="_blank" title="The Growing Footprint of Digitalisation">
                            <img src={project_1} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://theshiftproject.org/wp-content/uploads/2019/03/Lean-ICT-Report_The-Shift-Project_2019.pdf" target="_blank" title="Lean ICT Towards Digital Sobriety">
                            <img src={project_2} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://arxiv.org/pdf/1906.02243v1.pdf" target="_blank" title="Deep Learning in NLP">
                            <img src={project_5} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://bthechange.com/" target="_blank" title="B The Change">
                            <img src={project_6} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://en.reset.org/knowledge/our-digital-carbon-footprint-whats-the-environmental-impact-online-world-12302019" target="_blank" title="Our Digital Carbon Footprint">
                            <img src={project_7} className='img-fluid' />
                        </a>
                    </div>
                    <div className='online-library-box'>
                        <a href="https://www.genevaenvironmentnetwork.org/resources/updates/data-digital-technology-and-the-environment/" target="_blank" title="Data, Digital Technology and the Environment">
                            <img src={project_3} className='img-fluid' />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>;
}
