import React from 'react';
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import './adminStyles.css';
import { v4 as uuidv4 } from 'uuid';
import MyConstant from '../config/MyConstant';
import AdminTopMenu from "./AdminTopMenu"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginStatusCheck } from './Common';






export default class AddUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "", email: "", mobile: "", password: "",
            fileObj: {},
            errorObject: {}
        }
    }

    componentDidMount() {
        loginStatusCheck()
        document.body.style.background = "#DCDCDC";
        this.getUserDetails()
    }

    getUserDetails() {
        //auth: String
        let postJson = { auth: "Teqbahn@321#", };
        console.log("**", postJson)
        const request = new ZiFetchRequest();
        request.setDomain('manage');
        request.setEvent('getAllUsersList');
        request.setMessage(JSON.stringify(postJson));
        request.setDatetime(new Date().toString());
        this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
            if (response == null || response.getMessage() == 'Invalid Request') {
                console.warn('Invalid Request. Please try again later');
            } else {
                let responseData = JSON.parse(response.getMessage());
                let parseData = JSON.parse(responseData)
                console.log("responseData", parseData)

            }
        });

    }

    async addUserFunction() {
        let { username, email, mobile, password, fileObj } = this.state

        let errorObject = {}

        if (!username) {
            errorObject.username = "* Enter User Name"
        }
        if (!email) {
            errorObject.email = "* Enter Email"
        }
        else if (email) {
            let waitMail = this.ValidateEmail(email)
            if (!waitMail) {
                errorObject.email = "* Enter Valid MailId"
            }
            console.log("waitMail", waitMail)
        }
        if (!password) {
            errorObject.password = "* Enter Password"
        }
        if (Object.keys(fileObj).length == 0) {
            errorObject.fileObj = "* Choose Image"
        }

        this.setState({ errorObject })


        if (Object.keys(errorObject).length == 0) {
            let resImg = await this.filePostApi()
            console.log("reimg", resImg)
            //email : String, password : String,mobile: String,name:String
            let postJson = { email: email, password: password, mobile: mobile, name: username, profilePhoto: fileObj.fileName };
            console.log("**", postJson)
            const request = new ZiFetchRequest();
            request.setDomain('manage');
            request.setEvent('createUser');
            request.setMessage(JSON.stringify(postJson));
            request.setDatetime(new Date().toString());
            this.props.grpcClient.sayZiFetch(request, {}, (err, response) => {
                if (response == null || response.getMessage() == 'Invalid Request') {
                    console.warn('Invalid Request. Please try again later');
                } else {
                    let responseData = JSON.parse(response.getMessage());
                    console.log("responseData", responseData)
                    responseData = JSON.parse(responseData)
                    if (responseData) {
                        if (responseData.response == "Success") {
                            toast.success('Create User !.', {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }
                }
            });
        }
        else {
            console.log("validate")
        }

    }

    async filePostApi() {
        let { fileObj } = this.state
        let dataJson = fileObj
        if (dataJson != "{}" && Object.keys(dataJson).length > 0) {
            var i = 0
            const postFileUpload = new FormData();
            postFileUpload.append('file' + (i + 1), dataJson.file);
            postFileUpload.append('fileName' + (i + 1), dataJson.fileName);
            postFileUpload.append('processType' + (i + 1), dataJson.processType);
            postFileUpload.append('docsId' + (i + 1), dataJson.docsId);
            //  postFileUpload.append('userId', dataJson.userId);
            //  alert(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName)

            let imgres = await fetch(MyConstant.keyList.apiURL + "uploads/" + dataJson.processType + "/" + dataJson.fileType + "/" + dataJson.fileName, {
                method: "POST",
                mode: 'cors',
                redirect: 'follow',
                body: postFileUpload,
            }).then(function (response) {
                // console.log("response_1", response)
                return response.status == 200 ? true : false
            }
            )
            console.log("imgres", imgres)
            return imgres
        }
    }

    ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }

        return (false)
    }
    render() {
        let { username, email, mobile, password, fileObj, errorObject } = this.state
        return (<>
            <div className='adminPage'>
                <AdminTopMenu activeTab={"adduser"} />
                <ToastContainer />
                <div class="container">
                    <h1 style={{ textAlign: "center" }}>Add User</h1>
                    <hr />
                    <label for="psw-repeat"><b>Name</b></label>
                    <input type="text" placeholder="Enter Name" value={username} onChange={(e) => {
                        this.setState({ username: e.target.value })
                    }} />
                    <span className='error-style' > {errorObject.username} </span>
                    <hr />
                    <label for="psw-repeat"><b>Image</b></label>
                    <input type="file" onChange={async (event) => {
                        var files = event.target.files;
                        var length = files.length;
                        var urlfile = ''
                        if (length > 0) {
                            for (var i = 0; i < length; i++) {
                                var fileUrl = URL.createObjectURL(files[i]);
                                var file = files[i];
                                var filename = file.name;
                                var ext = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
                                // if(file.type == "image/jpeg"){
                                //     ext = "jpeg";
                                // }
                                urlfile = fileUrl
                                var uid = uuidv4();
                                var fileObj = {
                                    file: file,
                                    fileUrl: fileUrl,
                                    fileName: uid + "." + ext,
                                    docsId: uid,
                                    processType: "coverimage",
                                    fileType: "image"
                                };
                                console.log("fileObj", fileObj)
                                this.setState({ fileObj, fileType: ext })
                            }
                        }
                    }
                    }
                    />
                    <span className='error-style' > {errorObject.fileObj} </span>
                    <div className='mt-2 pt-2'>
                        {Object.keys(fileObj).length != 0 ?
                            <img src={fileObj.fileUrl} class="img-rounded" alt="Responsive image" style={{ width: 200, }} /> : null}
                    </div>
                    <hr />
                    <label for="email"><b>Email</b></label>
                    <input type="text" placeholder="Enter Email" name="email" id="email" value={email} onChange={(e) => {
                        this.setState({ email: e.target.value })
                    }} />
                    <span className='error-style' > {errorObject.email} </span>
                    <hr />
                    <label for="email"><b>Mobile</b></label>
                    <input type="text" placeholder="Enter Mobile" name="mobile" id="mobile" value={mobile} onChange={(e) => {
                        this.setState({ mobile: e.target.value })
                    }} />

                    <hr />
                    <label for="psw"><b>Password</b></label>
                    <input type="password" placeholder="Enter Password" name="psw" id="psw" value={password} onChange={(e) => {
                        this.setState({ password: e.target.value })
                    }} />
                    <span className='error-style' > {errorObject.password} </span>
                    <hr />
                    <button className="registerbtn" onClick={() => {
                        this.addUserFunction()
                    }}>Create User</button>
                </div>
            </div>
        </>)
    }

}