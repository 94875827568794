import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      holdImage: true,
      oneTimeSet: false,
    }
  }

  changeStatus(type) {
    this.setState({ holdImage: type })
  }
  render() {
    let { holdImage } = this.state;
    let { slider } = this.props;
    return (
      <div className='responsive slider'>
        <Carousel autoPlay={holdImage} interval={5000} width={"100%"} infiniteLoop={true} stopOnHover={false} showArrows={true} showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div onClick={onClickHandler}>
                <div onClick={() => { this.changeStatus(true) }} className='prevbtn'>
                  <i className='fa fa-chevron-left' />
                </div>
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div onClick={onClickHandler} >
                <div onClick={() => { this.changeStatus(true) }} title={label} className='nextbtn' >
                  <i className='fa fa-chevron-right' />
                </div>
              </div>
            )
          }
          showThumbs={false}
          animationHandler="fade"

        >
          {
            slider.map((slide, index) => {
              return <div key={index} onClick={() => {
                this.changeStatus(false)
              }} >
                {slide}
              </div>
            })
          }
        </Carousel>
      </div>
    )
  }
}
export default Slider;
