import React from 'react';
import { Style } from "react-style-tag";
import Logo from '../images/Blacktransparent.png'
import Header from './component/Header';
import { analyticsUserTrack } from '../config/analyticsTrack';
import Config from '../helper/Config';
import { Link } from "react-router-dom";

class TeqedV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
    }

    componentDidMount() {
        analyticsUserTrack("TeqEd", "Landing");
    }

    render() {
        let { bannerState } = this.props;
        return (
            <React.Fragment>

                <Style>
                    {`
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
       
                .nowrap {
                    white-space: nowrap !important;
                }
               
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                                          
                  @media screen and (max-width:600px) {            
                                                             
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                 
                    
                      .custom-p-tag {
                        font-size:1rem !important;
                    }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        // background-repeat: round !important;
                    }
                    .div_bg_img{
                        min-height: 230px
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    
                }


                .home-content {
                    margin-top: 0;
                }

                @media (min-width: 768px) {
                    .home-content {
                        margin-top: 250px;
                      }
                },
                                 
               `}
                </Style>

                <div>
                    <div className="version-2 header-with-menu teqed" style={{ paddingTop: bannerState ? (window.screen.width < 768 ? 142 : 30) : 0 }}>

                        <div className="logo padding about-sticky-crop" id="myHeader">
                            <Link to={Config.projectUrl + "/"} onClick={() => window.scrollTo(0, 0)}>
                                <img src={Logo} />
                            </Link>
                        </div>
                        <Header page="TeqEd" />

                        <div style={{ paddingTop: 135 }}>
                            <div data-v-709914d9="" className="reset-z-index">
                                <div>
                                    <div>
                                        <div className="container">
                                            <div className="row d-flex" style={{ marginTop: 10, marginBottom: 10 }}>
                                                <div className="col-sm-6 home-content" style={{ fontSize: 26, textAlign: 'center', fontFamily: "system-ui" }}>
                                                    TeqEd Changemaker <br /><br />
                                                    Member, Teqbahn Co-Founder Collective
                                                    Women’s rights activist, Afghanistan.
                                                </div>
                                                <div className="col-sm-6">
                                                    <img alt="" className="img-responsive" src="/Headshot2_1624200504.jpg" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: "#7fb5bc" }}>
                                            <div className="container">
                                                <div className="row" >
                                                    <div className="col-12 col-md-8 offset-0 col-md-offset-2" style={{ marginTop: "4%", marginBottom: "4%", padding: 20 }} >
                                                        <p style={{ fontSize: 24, fontFamily: "system-ui" }}>
                                                            My name is Sveto Muhammad Ishoq. I am a social entrepreneur and women's rights activist from Afghanistan.
                                                            <br /><br />
                                                            I have been fighting for women’s rights since I was 14 years old. I have worked for various women’s rights organizations and I have founded two women-centered initiatives in Afghanistan: Chadari project (<a href="http://www.chadariproject.com/" target="_blank" rel="noopener noreferrer">www.chadariproject.com</a>) and Ayat Modest Clothes. Ayat is a social enterprise aimed at empowering Afghan women through fashion. Chadari is a volunteer and youth-led organization aimed at eliminating stereotypes about Afghan women through storytelling. Driving social change through entrepreneurship is something I enjoy and value. The opportunity to work with and learn from other women while creating social change is very meaningful.
                                                            <br /><br />
                                                            I am working on projects addressing gender equality and education in Afghanistan, and I want to achieve even more to create a long-term impact with this work. My goal is to work in Afghanistan and implement gender-sensitive programming locally. Meanwhile, I want to expand the activities of my own projects, Ayat and Chadari, to reach women in more provinces of Afghanistan. In the future, I hope to join the Afghan government as a Gender Advisor, ensuring that gender-sensitive programming is emphasized across all government institutions.&nbsp;<br /><br />

                                                            Education has always occupied an important place in my life and career. I realized the value of education after the tragic events of August 24, 2016, when my undergraduate university, the American University of Afghanistan, was attacked by the Taliban and I lost several of my classmates. Witnessing that night taught me many lessons, and made me realize the power of education, especially of young women.  I worked hard and became Afghanistan's first Schwarzman Scholar and pursued my Master's Degree in Global Affairs in China in 2019.
                                                            &nbsp;<br /><br />
                                                            With the implementation of these projects, I learned that practical work without deep understanding of the subject is difficult. I understand that I needed to develop more skills related to solving gender inequality. In order to achieve my ambitious goals and develop necessary skills, I need to focus intensively on gender and development in a program that is practice-oriented and internationally-oriented. I applied, and have been accepted to pursue further education at the London School of Economics.
                                                            &nbsp;<br /><br />
                                                            I am committed to playing a significant role in addressing foundational inequities and misconceptions with the greater goal of bringing women into the economy as equal partners and helping to raise our voices at the national and international levels.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default TeqedV2;