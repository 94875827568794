import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAdminLoginData, setAdminAuthData } from "../redux/actions";
import { ZiFetchRequest } from '../protobuf/generated/ZiFetchService_grpc_web_pb';
import Logo from '../images/Blacktransparent.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyConstant from '../config/MyConstant';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                email: "",
                password: "",
                login_type: "",
            }
        }
    }

    render() {
        let { email, password } = this.props;
        let { errors } = this.state;
        return (
            <React.Fragment>
                
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        email: state.adminLoginReducer.email,
        password: state.adminLoginReducer.password,
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAdminLoginData: (key, value) => dispatch(setAdminLoginData(key, value)),
        setAdminAuthData: (key, value) => dispatch(setAdminAuthData(key, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);